import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import Konva from "konva";
import { memo, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { AsyncStates } from "src/constants";
import { StoreState } from "src/store/configureStore";
import useImage from "use-image";
import { addZoomAndPan } from "../../Shared/canvasZoomPan";

type TProps = {
    showAllMasks: boolean;
    filteredData: any;
    image: any;
    selections?: any[];
    showBBox: boolean;
};

const SCALE_STEPS = 1.1;

const PaintFilmAnalysisDetailsContent = ({
    showAllMasks,
    filteredData,
    image,
    selections,
    showBBox,
}: TProps) => {
    const [img] = useImage(image);
    const { semAnalysisMaskDataStatus, modifyPaintFilmStatus } = useSelector(
        (state: StoreState) => state.semAnalysis
    );
    const [mounted, setMounted] = useState(false);

    const stage = useMemo(() => {
        if(!mounted) return;

        // Setup
        const canvasWidth = img?.width || 1000;
        const canvasHeight = img?.height || 1000;

        const stage = new Konva.Stage({
            container: "image-masks", // id of container <div>
            width: canvasWidth,
            height: canvasHeight,
        });

        // Add zoom
        addZoomAndPan(stage, SCALE_STEPS);

        return stage;
    }, [img?.height, img?.width, mounted]);

    useEffect(() => {
        if(!mounted) setMounted(true);

       // Make sure data & stage is not empty
       if (!filteredData || !Array.isArray(filteredData) || !stage) return;

        const layer = new Konva.Layer();
        stage.add(layer);
        layer.draw();

        const layerForCirclesApi = new Konva.Layer();
        stage.add(layerForCirclesApi);
        layerForCirclesApi.draw();

        const layerForCircles = new Konva.Layer();
        stage.add(layerForCircles);
        layerForCircles.draw();

        // Adding Image
        const img_layer = new Konva.Image({
            image: img,
        });

        layer.add(img_layer);
        layer.batchDraw();

        let dataArr: any =
            selections && selections?.[0]
                ? filteredData.filter((item: any) => {
                    const { max_length, calibrated_length } = item;
                    const length =
                        calibrated_length?.value.replace(/[^0-9.]/g, "").trim() ||
                        max_length.pixel_length;
                    return length > selections[0]?.x0 && length < selections[0]?.x1;
                })
                : filteredData;

        dataArr?.forEach((mask: any) => {
            const pol = new Konva.Line({
                points: mask?.contour_points?.flat() ?? [],
                stroke: "green",
                fill: "transparent",
                strokeWidth: 0,
                closed: true,
                id: mask.id,
                visible: true,
            });

            const boundBoxData = pol?.getClientRect();

            const bbox = new Konva.Rect({
                x: boundBoxData.x,
                y: boundBoxData.y,
                width: boundBoxData.width,
                height: boundBoxData.height,
                fill: "transparent",
                stroke: "red",
                strokeWidth: 0,
                id: mask.id,
                visible: true,
            });

            if (showAllMasks) pol.fill(mask?.mask_color);
            if (showBBox) bbox.strokeWidth(2);

            bbox.on("mouseenter", () => {
                stage.container().style.cursor = "pointer";
                pol.fill(mask?.mask_color);
                bbox.strokeWidth(2);
            });
            bbox.on("mouseleave", () => {
                stage.container().style.cursor = "default";
                if (!showAllMasks) pol.fill("transparent");
                if (!showBBox) bbox.strokeWidth(0);
            });

            layer.add(pol);
            layer.add(bbox);
            bbox.moveToTop();
        });

        return () => {
            layer.removeChildren();
            layer.destroy();
        }
    }, [filteredData, img, showAllMasks, showBBox, selections, mounted, stage]);

    return (
        <div className="image-mask-container">
            <Spin
                indicator={<LoadingOutlined style={{ color: "white" }} />}
                spinning={[semAnalysisMaskDataStatus, modifyPaintFilmStatus].includes(AsyncStates.LOADING)}
                style={{ height: "100%", width: "100%", marginTop: "50px" }}
            >
                <div
                    id="image-masks"
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        overflow: "auto",
                    }}
                />
            </Spin>
        </div>
    );
};

export default memo(PaintFilmAnalysisDetailsContent);
