import {
    ArrowLeftOutlined,
    ExclamationCircleOutlined,
    LoadingOutlined,
} from "@ant-design/icons";
import { Empty, Modal, Spin } from "antd";
import {
    useCallback,
    useEffect,
    useLayoutEffect,
    useMemo,
    useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "src/store/configureStore";
import {
    deleteSemAnalysisFilesClear,
    deleteFiberBatchClear,
    deleteFiberBatchRequest,
    fetchFiberBatchInfoRequest,
    processFiberAnalysisClear,
    fiberRecalibrateScaleClear,
    fiberRecalibrateScaleRequest,
    downloadBatchReportRequest,
    downloadSegmentedImagesRequest,
} from "src/store/actions/semAnalysis";
import { AsyncStates } from "src/constants";
import { useHistory, useParams } from "react-router-dom";
import ReCalibrateModal from "../Shared/ReCalibrateModal";
import { IMAGE_ANALYSIS_TYPES } from "../SEMAnalysisWrapper";
import ThumbnailList from "../Shared/ThumbnailList";
import BatchCard from "../Shared/BatchCard";
import SelectionsActions from "../Shared/SelectionsActions";
import useTranslate from "src/utils/useTranslate";

const FiberAnalysis = () => {
    const [t] = useTranslate();
    const dispatch = useDispatch();
    const params = useParams<any>();
    const {
        fiberBatchInfo,
        fiberBatchInfoStatus,
        deleteSemAnalysisFilesStatus,
        deleteFiberBatchStatus,
        processFiberAnalysisStatus,
        fiberRecalibrateScaleStatus,
        downloadBatchReportStatus,
        analysisTypes,
        downloadSegmentedImagesStatus
    } = useSelector((state: StoreState) => state.semAnalysis);
    const history = useHistory();
    const [view, setView] = useState<"batch" | "expanded">("batch");
    const [selectedBatch, setSelectedBatch] = useState<string>();
    const [reCalibrateModalOpen, setReCalibrateModalOpen] = useState(false);
    const [selectedIds, setSelectedIds] = useState<string[]>([]);

    useLayoutEffect(() => {
        if (Array.isArray(fiberBatchInfo) && params.batchId) {
            setView("expanded");
            setSelectedBatch(params.batchId);
        } else {
            setSelectedBatch(undefined);
            setView("batch");
        }
    }, [fiberBatchInfo, params]);

    const selectedBatchInfo = useMemo(() => {
        if (
            !selectedBatch ||
            !Array.isArray(fiberBatchInfo) ||
            fiberBatchInfo.length === 0
        )
            return null;

        return fiberBatchInfo.find((batch) => batch.batch_id === selectedBatch);
    }, [selectedBatch, fiberBatchInfo]);

    useEffect(() => {
        if (fiberBatchInfoStatus === AsyncStates.SUCCESS) {
            if((!fiberBatchInfo || fiberBatchInfo.length === 0)) {
                history.push(`/sem-analysis/${IMAGE_ANALYSIS_TYPES.FIBER_ANALYSIS}`);
            } else if(view === "expanded" && (!selectedBatchInfo || selectedBatchInfo?.thumbnails?.length === 0)) {
                history.push(`/sem-analysis/${IMAGE_ANALYSIS_TYPES.FIBER_ANALYSIS}`);
            }
        }
    }, [history, fiberBatchInfo, fiberBatchInfoStatus, selectedBatchInfo, view]);

    const fetchFiberBatch = useCallback(() => {
        dispatch(fetchFiberBatchInfoRequest({
            batch_id: params?.batchId,
            analysis_type:
                analysisTypes[
                IMAGE_ANALYSIS_TYPES.FIBER_ANALYSIS as keyof typeof analysisTypes
                ]
        }));
    }, [analysisTypes, dispatch, params?.batchId])

    useEffect(() => {
        fetchFiberBatch();
    }, [fetchFiberBatch]);

    useEffect(() => {
        if (
            [
                deleteSemAnalysisFilesStatus,
                deleteFiberBatchStatus
            ].includes(AsyncStates.SUCCESS)
        ) {
            fetchFiberBatch();
            dispatch(deleteSemAnalysisFilesClear());
            dispatch(deleteFiberBatchClear());
            setSelectedIds([]);
        }
    }, [deleteFiberBatchStatus, deleteSemAnalysisFilesStatus, dispatch, fetchFiberBatch]);

    useEffect(() => {
        if (
            [
                processFiberAnalysisStatus,
                fiberRecalibrateScaleStatus,
            ].includes(AsyncStates.SUCCESS)
        ) {
            fetchFiberBatch();
            dispatch(processFiberAnalysisClear());
            dispatch(fiberRecalibrateScaleClear());
        }
    }, [dispatch, fetchFiberBatch, fiberRecalibrateScaleStatus, processFiberAnalysisStatus]);

    const handleReCalibrate = useCallback(
        (scale_length: any, unit: any) => {
            const payload = {
                scale: `${scale_length || ""} ${unit || ""}`,
                batch_id: selectedBatch,
                analysis_type: analysisTypes[
                    IMAGE_ANALYSIS_TYPES.FIBER_ANALYSIS as keyof typeof analysisTypes
                ]
            };
            dispatch(fiberRecalibrateScaleRequest(payload));
        },
        [analysisTypes, dispatch, selectedBatch]
    );

    const gotoBatchView = () => history.push(`/sem-analysis/${IMAGE_ANALYSIS_TYPES.FIBER_ANALYSIS}`);

    const goToBatchDetails = (batchId: string) => {
        history.push(
            `/sem-analysis/${IMAGE_ANALYSIS_TYPES.FIBER_ANALYSIS}/batch/${batchId}`
        );
    };

    const goToFileDetails = useCallback(
        ({ fileId }: any) => {
            history.push(
                `/sem-analysis/${IMAGE_ANALYSIS_TYPES.FIBER_ANALYSIS}/batch/${selectedBatchInfo.batch_id}/files/${fileId}`
            );
        },
        [history, selectedBatchInfo?.batch_id]
    );

    const selectAll = useCallback(() => {
        if (
            view === "expanded" &&
            selectedBatchInfo?.thumbnails &&
            Array.isArray(selectedBatchInfo.thumbnails)
        ) {
            if (selectedIds.length !== selectedBatchInfo?.thumbnails.length)
                setSelectedIds([
                    ...selectedBatchInfo.thumbnails.map((t: any) => t.file_id),
                ]);
            else setSelectedIds([]);
        }

        if (view === "batch" && Array.isArray(fiberBatchInfo)) {
            if (selectedIds.length !== fiberBatchInfo.length)
                setSelectedIds([...fiberBatchInfo.map((t: any) => t.batch_id)]);
            else setSelectedIds([]);
        }
    }, [
        fiberBatchInfo,
        selectedBatchInfo?.thumbnails,
        selectedIds.length,
        view,
    ]);

    const deleteFiles = useCallback(() => {
        Modal.confirm({
            title: t("inventory.Areyousure?"),
            icon: <ExclamationCircleOutlined />,
            okText: t("common.delete"),
            okType: "danger",
            cancelText: t("common.Cancel"),
            onOk() {
                const payload: any = {
                    batch_ids: selectedBatch ? [selectedBatch] : [],
                    file_ids: selectedIds || [],
                    analysis_type:
                        analysisTypes[
                        IMAGE_ANALYSIS_TYPES.FIBER_ANALYSIS as keyof typeof analysisTypes
                        ],
                };
                dispatch(deleteFiberBatchRequest(payload));
            },
        });
    }, [analysisTypes, dispatch, selectedBatch, selectedIds, t]);

    const deleteBatches = useCallback(() => {
        Modal.confirm({
            title: t("inventory.Areyousure?"),
            icon: <ExclamationCircleOutlined />,
            okText: t("common.delete"),
            okType: "danger",
            cancelText: t("common.Cancel"),
            onOk() {
                const payload: any = {
                    batch_ids: selectedIds || [],
                    analysis_type:
                        analysisTypes[
                        IMAGE_ANALYSIS_TYPES.FIBER_ANALYSIS as keyof typeof analysisTypes
                        ],
                };
                dispatch(deleteFiberBatchRequest(payload));
            },
        });
    }, [analysisTypes, dispatch, selectedIds, t]);

    const downloadReport = useCallback(() => {
        const payload: any = {
            batch_id: selectedBatch,
            file_ids: selectedIds || [],
            analysis_type:
                analysisTypes[
                IMAGE_ANALYSIS_TYPES.FIBER_ANALYSIS as keyof typeof analysisTypes
                ],
        };

        dispatch(downloadBatchReportRequest(payload));
    }, [analysisTypes, dispatch, selectedBatch, selectedIds]);

    const downloadSegmentedImages = () => {
        const payload: any = {
            batch_id: selectedBatch,
            file_ids: selectedIds || [],
            analysis_type:
                analysisTypes[
                IMAGE_ANALYSIS_TYPES.FIBER_ANALYSIS as keyof typeof analysisTypes
                ],
            batch_name: selectedBatchInfo?.batch_name
        };

        dispatch(downloadSegmentedImagesRequest(payload));
    }

    return (
        <Spin
            spinning={[
                fiberBatchInfoStatus,
                deleteSemAnalysisFilesStatus,
                deleteFiberBatchStatus,
            ].includes(AsyncStates.LOADING)}
            indicator={<LoadingOutlined />}
        >
            <div className="paint-film-analysis-container">
                {Array.isArray(fiberBatchInfo) && (
                    <>
                        {view === "batch" && (
                            <>
                                <SelectionsActions
                                    count={fiberBatchInfo ? fiberBatchInfo.length : 0}
                                    selectedIds={selectedIds}
                                    onSelectAll={selectAll}
                                    showDownloadReport={false}
                                    onDelete={deleteBatches}
                                />
                                <div className="batch-container">
                                    {fiberBatchInfo.map((batch) => (
                                        <BatchCard
                                            batch={batch}
                                            onBatchClick={goToBatchDetails}
                                            selectable={true}
                                            selectedIds={selectedIds}
                                            setSelectedIds={setSelectedIds}
                                        />
                                    ))}
                                    {fiberBatchInfoStatus !== AsyncStates.LOADING &&
                                        (!fiberBatchInfo ||
                                            fiberBatchInfo.length === 0) && <Empty />}
                                </div>
                            </>
                        )}
                        {view === "expanded" && (
                            <>
                                <div className="content-container">
                                    <div className="content-header">
                                        <div
                                            className="back"
                                            onClick={() => {
                                                gotoBatchView();
                                            }}
                                        >
                                            <ArrowLeftOutlined />
                                        </div>
                                        {selectedBatchInfo ? (
                                            <>
                                                <div className="batch-name">
                                                    {selectedBatchInfo?.batch_name}
                                                </div>
                                                <div>
                                                    {t("sem.scale")}: {selectedBatchInfo?.scale?.scale}{" "}
                                                    {selectedBatchInfo?.scale?.unit}
                                                </div>
                                                <div
                                                    className="link"
                                                    onClick={() => setReCalibrateModalOpen(true)}
                                                >
                                                    {t("sem.recalibrateScale")}
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                {fiberBatchInfoStatus !== AsyncStates.LOADING && (
                                                    <Empty />
                                                )}
                                            </>
                                        )}
                                    </div>
                                    <SelectionsActions
                                        count={
                                            selectedBatchInfo?.thumbnails &&
                                                Array.isArray(selectedBatchInfo.thumbnails)
                                                ? selectedBatchInfo.thumbnails.length
                                                : 0
                                        }
                                        selectedIds={selectedIds}
                                        onSelectAll={selectAll}
                                        onDownloadReport={downloadReport}
                                        downloadReportStatus={downloadBatchReportStatus}
                                        onDelete={deleteFiles}
                                        showDownloadImages={true}
                                        onDownloadImages={downloadSegmentedImages}
                                        downloadImageStatus={downloadSegmentedImagesStatus}
                                    />
                                    <div className="thumb-container">
                                        <ThumbnailList
                                            loadingDataMap={{}}
                                            handleCardClick={goToFileDetails}
                                            thumbnailList={
                                                selectedBatchInfo?.thumbnails &&
                                                    Array.isArray(selectedBatchInfo?.thumbnails)
                                                    ? selectedBatchInfo?.thumbnails
                                                    : []
                                            }
                                            loading={fiberBatchInfoStatus === AsyncStates.LOADING}
                                            selectable={true}
                                            setSelectedIds={setSelectedIds}
                                            selectedIds={selectedIds}
                                        />
                                    </div>
                                </div>
                                <ReCalibrateModal
                                    reCalibrateModalOpen={reCalibrateModalOpen}
                                    setReCalibrateModalOpen={setReCalibrateModalOpen}
                                    handleReCalibrate={handleReCalibrate}
                                    selectedBatchInfo={selectedBatchInfo}
                                />
                            </>
                        )}
                    </>
                )}
            </div>
        </Spin>
    );
};

export default FiberAnalysis;
