import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import Konva from "konva";
import { memo, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { AsyncStates } from "src/constants";
import { StoreState } from "src/store/configureStore";
import useImage from "use-image";
import { addZoomAndPan } from "../../Shared/canvasZoomPan";

type TProps = {
    showAllMasks: boolean;
    filteredData: any;
    image: any;
    showBBox: boolean;
    selectedMasks: string[]
    setSelectedMasks: React.Dispatch<React.SetStateAction<string[]>>
    imgOpacity: number
};

const SCALE_STEPS = 1.1;

const ResidualAnalysisDetailsContent = ({
    showAllMasks,
    filteredData,
    image,
    showBBox,
    selectedMasks,
    setSelectedMasks,
    imgOpacity,
}: TProps) => {
    const [img] = useImage(image);
    const imageSubtractionStatus = useSelector(
        (state: StoreState) => state.semAnalysis.imageSubtractionStatus
    );
    const [mounted, setMounted] = useState(false);

    const stage = useMemo(() => {
        if(!mounted) return;

        // Setup
        const canvasWidth = img?.width || 1000;
        const canvasHeight = img?.height || 1000;

        const stage = new Konva.Stage({
            container: "image-masks", // id of container <div>
            width: canvasWidth,
            height: canvasHeight,
        });

        // Add zoom
        addZoomAndPan(stage, SCALE_STEPS);

        return stage;
    }, [img?.height, img?.width, mounted]);

    useEffect(() => {
        if(!mounted) setMounted(true);

       // Make sure data & stage is not empty
       if (!filteredData || !Array.isArray(filteredData) || !stage) return;

        const layer = new Konva.Layer();
        stage.add(layer);
        layer.draw();

        const layerForCirclesApi = new Konva.Layer();
        stage.add(layerForCirclesApi);
        layerForCirclesApi.draw();

        const layerForCircles = new Konva.Layer();
        stage.add(layerForCircles);
        layerForCircles.draw();

        // Adding Image
        const img_layer = new Konva.Image({
            image: img,
            opacity: (imgOpacity / 100)
        });

        layer.add(img_layer);
        layer.batchDraw();

        let dataArr: any = filteredData;

        dataArr?.forEach((mask: any) => {
            const pol = new Konva.Line({
                points: mask?.mask_polygon?.flat() ?? [],
                stroke: "white",
                fill: "transparent",
                strokeWidth: 0,
                closed: true,
                id: mask.id,
                visible: true,
            });

            const boundBoxData = pol?.getClientRect();

            const bbox = new Konva.Rect({
                x: boundBoxData.x,
                y: boundBoxData.y,
                width: boundBoxData.width,
                height: boundBoxData.height,
                fill: "transparent",
                stroke: "green",
                strokeWidth: 0,
                id: mask.id,
                visible: true,
            });

            if (showAllMasks) pol.fill("red");
            if (showBBox) bbox.strokeWidth(2);

            bbox.on("mouseenter", () => {
                stage.container().style.cursor = "pointer";
                pol.strokeWidth(2);
                bbox.strokeWidth(2);
            });
            bbox.on("mouseleave", () => {
                stage.container().style.cursor = "default";
                pol.strokeWidth(0);
                if (!selectedMasks.includes(mask.id)) {
                    if (!showAllMasks) pol.fill("transparent");
                    if (!showBBox) bbox.strokeWidth(0);
                }
            });

            if (selectedMasks.includes(mask.id)) {
                bbox.stroke("red");
                bbox.strokeWidth(2);
                pol.fill("red");
            }

            // Select masks for deletion
            bbox.on("click", () => {
                if (selectedMasks.includes(mask.id)) {
                    setSelectedMasks((prev: any) =>
                        prev.filter((id: string) => id !== mask.id)
                    );
                } else {
                    setSelectedMasks((prev: any) => [...prev, mask.id]);
                }
            });

            layer.add(pol);
            layer.add(bbox);
            bbox.moveToTop();
        });

        return () => {
            layer.removeChildren();
            layer.destroy();
        }
    }, [filteredData, img, imgOpacity, mounted, selectedMasks, setSelectedMasks, showAllMasks, showBBox, stage]);

    return (
        <div className="image-mask-container">
            <Spin
                indicator={<LoadingOutlined style={{ color: "white" }} />}
                spinning={[imageSubtractionStatus].includes(AsyncStates.LOADING)}
                style={{ height: "100%", width: "100%", marginTop: "50px" }}
            >
                <div
                    id="image-masks"
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        overflow: "auto",
                    }}
                />
            </Spin>
        </div>
    );
};

export default memo(ResidualAnalysisDetailsContent);
