import "../../../WorkOrderDetails/react-datasheet.css"
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  Space,
  Select,
  message,
  Checkbox,
  Form,
  Tooltip,
  Segmented,
  Typography,
  Slider,
  Row,
  Input,
  Modal,
} from "antd";
import { LockFilled } from "@ant-design/icons";
import Text from "antd/lib/typography/Text"
import { useDispatch, useSelector } from "react-redux"
import { StoreState } from "src/store/configureStore"
import { antdTheme, AsyncStates, } from "src/constants"
import useTranslate from "src/utils/useTranslate"
import { StyledButton } from "src/styled_components/StyledButton"
import { clearSuggestedExpInsights } from "src/store/actions/insights"
import { newInverseModelFilter, newInverseModelRequest } from "src/store/actions/newInverseModel"
import {
  CategoricalObjectiveList,
  isCategoricalProperties,
  CategoricalProperty,
  NumericalProperty,
  NumericalPropertyErrors,
  CategoricalPropertyErrors,
} from "../types"
import { ZeonCharacterizationSelection } from "src/components/AIEngine/common"
import jwtManager from "src/utils/jwtManager"
import { suggestedExperimentsClear } from "src/store/actions/suggestedExp"
import { StyledCard } from "src/styled_components/StyledCard"
import { useQuery } from "src/utils/useQuery"
import { ModelConfig } from "src/typings/model-config"
import { modelsConfigRequest } from "src/store/actions/formulate"
import PayloadInput from "./input"
import './child.scss'
import { DroppedPropertyWarning } from "../../common/DroppedPropertyWarning";
import { useValue } from "src/utils/useValue";
import { isValidNumber } from "src/utils/decorator";
import StyledDeleteIcon from "src/styled_components/StyledDeleteIcon";
import { COLLAPSED_SIDEBAR_WIDTH, OPEN_SIDEBAR_WIDTH } from "src/router/AppRouter";
import { doc, onSnapshot } from "firebase/firestore";
import { firestoreDb } from "src/utils/firebase";
import { notification } from "antd/lib";
import { messages } from "src/utils/hooks";
import { Unsubscribe } from "firebase/auth";
import { ErrorText, PADDING_TOP_FOR_DATASHEET_CELLS } from "./InputConstraintsNew";
const { Option } = Select


export const CATEGORY_CONSTRAINTS_ERROR_KEYS = [
  'error_min',
  'error_max',
  'error_parameter',
]

export const PARAMETER_CONSTRAINTS_ERROR_KEYS = [
  'error_min',
  'error_max',
  'error_parameter',
]

export const NUMERICAL_OBJECTIVES_ERROR_KEYS = [
  'error_min',
  'error_max',
  'error_parameter',
  'error_priority',
]

export const CATEGORICAL_OBJECTIVES_ERROR_KEYS = [
  'error_parameter',
  'error_val'
]

interface P {
  setUnsavedChanges: any
  tab: any,
  setExperimentCurrent: any,
  setSelectedObjective: any,
  showAddFavoriteModal: any
  setShowAddFavoriteModal: any
  showFavouritesDrawer: any
  setShowFavouritesDrawer: any
  setEnabledStages?: any
}
export type InverseModelProps = P

export type ExludeAllParameterConstraintsTypes = {
  [key: string]: {
    [key: string]: {
      [key: string]: string[]
    }
  }
}

export type ExludeAllCategoryConstraintsTypes = { [key: string]: { [key: string]: string[] } }

export type SelectAllIngredientsInCategoryTypes = {
  [key: string]: {
    [key: string]: {
      [key: string]: string[]
    }
  }
}

const initialStateExludeAllCategoryConstraints = {
  1: {
    "ingredients": []
  }
}

const initialStateExludeAllParameterConstraints = {
  1: {
    "numerical_parameter": {}
  }
}

const initialStateSelectAllIngredientsInCategory = {
  1: {
    numerical: {},
    categorical: {}
  }
}

export type crossCategoricalState = {
  [key: string]: boolean
}

export type crossCategoricalSelectedCategories = {
  [key: string]: string[]
}

export type crossCategoricalUseDefault = {
  [key: string]: boolean
}

export type crossCategoricalValuesType = {
  [key: string]: {
    min: string | null,
    max: string | null
  }
}

const initialStateCrossCategorical = {
  1: false
}

const initialStateCrossCategoricalUseDefault = {
  1: false
}

const initialStateCrossCategoricalSelectedCategories = {
  1: []
}

const initialStateCrossCategoricalValues = {
  1: {
    min: null,
    max: null
  }
}

const unsubList: Unsubscribe[] = [];

export const InverseModel = ({
  setUnsavedChanges,
  tab,
  setExperimentCurrent,
  setSelectedObjective,
  showAddFavoriteModal,
  setShowAddFavoriteModal,
  showFavouritesDrawer,
  setShowFavouritesDrawer,
  setEnabledStages
}: P) => {
  const dispatch = useDispatch()
  const { getValue, convertValue } = useValue()
  const [t] = useTranslate()
  const [inverseForm] = Form.useForm()
  let query = useQuery();
  let modelVersion = query?.get('version');
  const [title, setTitle] = useState<string>('')
  const inverseModel = useSelector((state: StoreState) => state.inverseModel)
  const newInverseModel = useSelector(
    (state: StoreState) => state.newInverseModel
  )

  const [configsOpenedViaPredictButton, setConfigsOpenedViaPredictButton] = useState<boolean>(false)

  const labels = useSelector(
    (state: StoreState) => state.displayNames.data || {}
  )
  const { current: currentProject } = useSelector(
    (state: StoreState) => state.projects
  )
  const configs = useSelector((state: StoreState) => state.configs.features)

  const {
    configData,
    modelConfigData,
    mlProjectSpecificData,
    mlProjectSpecificDataZeon,
  } = useSelector(
    (state: StoreState) => state.formulate
  )
  const language = useSelector((state: StoreState) => state.language.current);
  const defaultInverseConfigurationData = useSelector((state: StoreState) => state.inverseModel.inverseConfigData)
  const inverseTasks = useSelector((state: StoreState) => state.newInverseModel.inverseModelTasks);

  const [isMultiStageModel, setIsMultiStageModel] = useState((configData.find((modelData: ModelConfig) => modelData.version === Number(modelVersion))?.is_multistage ?? false))
  const [selectedStages, setSelectedStages] = useState<[number, number]>([1, 1])
  const [currentSelectedStage, setCurrentSelectedStage] = useState<number>(1);
  const [activePredictionInputTab, setActivePredictionInputTab] = useState<{ [key: string]: any }>({
    1: 0
  })

  /**
   * 0 : Objectives
   * 1 : Constraints
   * 2 : Configuration
   */
  const [taskTracker, setTaskTracker] = useState<string[]>([]);
  const [rangeParameterList, setRangeParameterList] = useState<{ [key: string]: any[] }>({ 1: [] })
  const [catwiseRangeParameterList, setCatwiseRangeParameterList] = useState<{ [key: string]: any[] }>({ 1: [] })
  const [categorialInputList, setCategorialInputList] = useState<{ [key: string]: any[] }>({ 1: [] })
  const [selectedCharacterization, setSelectedCharacterization] = useState<{ [key: string]: any }>({ 1: { inverse: {}, forward: {} } })
  const [numericalProperties, setRange] = useState<{ [key: string]: (NumericalProperty & NumericalPropertyErrors)[] }>({
    1: [
      {
        parameter: "",
        output_range_optimal: `(0 - 0)`,
        min: "",
        max: "",
        checked: false,
        modelType: "",
        value: "",
        priority: null,
      },
    ]
  })
  const [catConstraintsList, setCatConstraintsList] = useState<{
    [key: string]: any[]
  }>({ 1: [] })

  const modelData = useMemo(() => {
    if (!Boolean(modelVersion)) {
      if (
        (mlProjectSpecificData?.properties &&
          Object.keys(mlProjectSpecificData?.properties).length > 0) ||
        (Boolean(configs?.characterization_methods) &&
          Object.keys(mlProjectSpecificDataZeon?.properties || {}).length > 0)
      )
        return undefined
      else
        return Boolean(configs?.characterization_methods)
          ? mlProjectSpecificDataZeon
          : mlProjectSpecificData
    } else {
      return modelConfigData?.[currentSelectedStage - 1]
    }
  }, [
    configs?.characterization_methods,
    currentSelectedStage,
    mlProjectSpecificData,
    mlProjectSpecificDataZeon,
    modelConfigData,
    modelVersion,
  ])

  const currentStageModelConfigData = useMemo(() => modelConfigData?.[currentSelectedStage - 1], [currentSelectedStage, modelConfigData])

  const [categoricalRange, setCategoricalRange] = useState<{
    [key: string]: (CategoricalProperty & CategoricalPropertyErrors)[]
  }>({
    1: [
      {
        parameter: "",
        modelType: "range",
        output_range_optimal: `(0 - 0)`,
        val: [],
        checked: false,
      },
    ],
  })

  const [selectAllIngredientsInCategory, setSelectAllIngredientsInCategory] = useState<SelectAllIngredientsInCategoryTypes>({ ...initialStateSelectAllIngredientsInCategory })
  const [exludeAllParameterConstraints, setExludeAllParameterConstraints] = useState<ExludeAllParameterConstraintsTypes>({ ...initialStateExludeAllParameterConstraints })
  const [exludeAllCategoryConstraints, setExludeAllCategoryConstraints] = useState<ExludeAllCategoryConstraintsTypes>({ ...initialStateExludeAllCategoryConstraints })

  useEffect(() => {
    setExludeAllCategoryConstraints({ ...initialStateExludeAllCategoryConstraints })
    setExludeAllParameterConstraints({ ...initialStateExludeAllParameterConstraints })
    setSelectAllIngredientsInCategory({ ...initialStateSelectAllIngredientsInCategory })
  }, [modelVersion])

  const [isCrossCategorical, setIsCrossCategorical] = useState<crossCategoricalState>({ ...initialStateCrossCategorical })
  const [crossCategoricalSelectedCategories, setCrossCategoricalSelectedCategories] = useState<crossCategoricalSelectedCategories>({ ...initialStateCrossCategoricalSelectedCategories })
  const [isCategoricalConstraintsUseDefault, setIsCategoricalConstraintsUseDefault] = useState<crossCategoricalUseDefault>({ ...initialStateCrossCategoricalUseDefault })
  const [crossCategoricalValues, setCrossCategoricalValues] = useState<crossCategoricalValuesType>({ ...initialStateCrossCategoricalValues })

  const [isCrossCategoricalModalVisible, setIsCrossCategoricalModalVisible] = useState<boolean>(false)

  const [inverseConfigurationData, setInverseConfigurationData] = useState<{ [key: string]: any }>({
    1: defaultInverseConfigurationData
  })

  useEffect(() => {
    setIsCrossCategorical({ ...initialStateCrossCategorical })
    setCrossCategoricalSelectedCategories({ ...initialStateCrossCategoricalSelectedCategories })
    setIsCategoricalConstraintsUseDefault({ ...initialStateCrossCategoricalUseDefault })
    setCrossCategoricalValues({ ...initialStateCrossCategoricalValues })
  }, [modelVersion])

  useEffect(() => {
    const initalInverseConfigurationData = {
      1: defaultInverseConfigurationData
    }
    setInverseConfigurationData({ ...initalInverseConfigurationData })
  }, [defaultInverseConfigurationData]);

  useEffect(() => {
    if(selectedStages && setEnabledStages) setEnabledStages(selectedStages);
  }, [selectedStages, setEnabledStages]);

  const listenToUpdates = useCallback((task: any) => {
    try {
      const taskQuery = doc(firestoreDb, `${task.firestore_collection}/${task.prediction_id}`);
      const unsub = onSnapshot(taskQuery, (doc) => {
        const taskResponse = doc.data();
        if (taskResponse?.status === "completed") {
          notification.success({
            duration: 5,
            message: messages[language].ai_engine_inverse_results_ready,
          });
          dispatch(newInverseModelFilter(task.prediction_id));
          unsub && unsub();
        }

        if (taskResponse?.status === "no_results_found") {
          notification.error({
            duration: 5,
            message: messages[language].noResultFound,
          });
          dispatch(newInverseModelFilter(task.prediction_id));
          unsub && unsub();
        }

        if (taskResponse?.status === "failed") {
          notification.error({
            duration: 5,
            message: messages[language].could_not_fetch_results
          })
          dispatch(newInverseModelFilter(task.prediction_id));
          unsub && unsub();
        }
      });
      unsubList.push(unsub);
    } catch (error) {
    }
  }, [dispatch, language])

  useEffect(() => {
    if (inverseTasks && Array.isArray(inverseTasks)) {
      inverseTasks.forEach(task => {
        if (!taskTracker.includes(task.prediction_id)) {
          listenToUpdates(task);
          setTaskTracker(prev => [...prev, task.prediction_id])
        }
      });
    }
  }, [inverseTasks, language, dispatch, taskTracker, listenToUpdates]);

  useEffect(() => {
    return () => unsubList.forEach(unsub => unsub && typeof unsub === "function" && unsub());
  }, []);

  // For Range Optimization
  const [statusRange, setStatusRange] = useState<AsyncStates>(
    AsyncStates.INITIAL,
  )

  useEffect(() => {
    setStatusRange(
      inverseModel?.statusRange.find(
        (res: any) => res.version === modelData?.version,
      )?.status || [],
    )
  }, [inverseModel, modelData])

  useEffect(() => {
    if (showAddFavoriteModal.isModalVisible) {
      setShowAddFavoriteModal((prev: any) => ({
        ...prev,
        data: {
          model_name: `${modelData.objective} (${modelData.comments})`,
          model_version: modelData.version,
          user_input_data: {
            range_parameter_list: rangeParameterList,
            catwise_range_parameter_list: catwiseRangeParameterList,
            categorial_input_list: categorialInputList,
            selected_characterization: selectedCharacterization,
            numerical_properties: numericalProperties,
            categorical_range: categoricalRange,
            select_all_ingredients_in_category: selectAllIngredientsInCategory,
            exlude_all_parameter_constraints: exludeAllParameterConstraints,
            exlude_all_category_constraints: exludeAllCategoryConstraints,
            is_cross_categorical: isCrossCategorical,
            cross_categorical_selected_categories: crossCategoricalSelectedCategories,
            is_categorical_constraints_use_default: isCategoricalConstraintsUseDefault,
            cross_categorical_values: crossCategoricalValues,
            inverse_configuration_data: inverseConfigurationData,
            cat_constraints_list: catConstraintsList
          }
        }
      }))
    }
  }, [showAddFavoriteModal.isModalVisible, setShowAddFavoriteModal, modelData, rangeParameterList, catwiseRangeParameterList, categorialInputList, selectedCharacterization, numericalProperties, categoricalRange, selectAllIngredientsInCategory, exludeAllParameterConstraints, exludeAllCategoryConstraints, isCrossCategorical, crossCategoricalSelectedCategories, isCategoricalConstraintsUseDefault, crossCategoricalValues, inverseConfigurationData, catConstraintsList])

  useEffect(() => {
    if (!!Object.keys(showFavouritesDrawer?.selectedData?.user_input_data ?? {}).length) {
      const userSelectedFavouritesData = JSON.parse(JSON.stringify(showFavouritesDrawer?.selectedData?.user_input_data))

      if (userSelectedFavouritesData?.range_parameter_list) {
        const stages = Object.keys(userSelectedFavouritesData?.range_parameter_list)
        for (let key of stages) {
          setRangeParameterList((prev) => {
            return {
              ...prev,
              [key]: userSelectedFavouritesData?.range_parameter_list?.[key]
            }
          })
        }
      }
      if (userSelectedFavouritesData?.categorial_input_list) {
        const stages = Object.keys(userSelectedFavouritesData?.categorial_input_list)
        for (let key of stages) {
          setCategorialInputList((prev) => {
            return {
              ...prev,
              [key]: userSelectedFavouritesData?.categorial_input_list?.[key]
            }
          })
        }
      }

      if (userSelectedFavouritesData?.selected_characterization) {
        const stages = Object.keys(userSelectedFavouritesData?.selected_characterization)
        for (let key of stages) {
          setSelectedCharacterization((prev) => {
            return {
              ...prev,
              [key]: userSelectedFavouritesData?.selected_characterization?.[key]
            }
          })
        }
      }
      if (userSelectedFavouritesData?.numerical_properties) {
        const stages = Object.keys(userSelectedFavouritesData?.numerical_properties)
        for (let key of stages) {
          setRange((prev) => {
            return {
              ...prev,
              [key]: userSelectedFavouritesData?.numerical_properties?.[key]
            }
          })
        }
      }
      if (userSelectedFavouritesData?.categorical_range) {
        const stages = Object.keys(userSelectedFavouritesData?.categorical_range)
        for (let key of stages) {
          setCategoricalRange((prev) => {
            return {
              ...prev,
              [key]: userSelectedFavouritesData?.categorical_range?.[key]
            }
          })
        }
      }

      if (userSelectedFavouritesData?.select_all_ingredients_in_category) {
        const stages = Object.keys(userSelectedFavouritesData?.select_all_ingredients_in_category)
        for (let key of stages) {
          setSelectAllIngredientsInCategory((prev) => {
            return {
              ...prev,
              [key]: userSelectedFavouritesData?.select_all_ingredients_in_category?.[key]
            }
          })
        }
      }
      if (userSelectedFavouritesData?.exlude_all_parameter_constraints) {
        const stages = Object.keys(userSelectedFavouritesData?.exlude_all_parameter_constraints)
        for (let key of stages) {
          setExludeAllParameterConstraints((prev) => {
            return {
              ...prev,
              [key]: userSelectedFavouritesData?.exlude_all_parameter_constraints?.[key]
            }
          })
        }
      }
      if (userSelectedFavouritesData?.exlude_all_category_constraints) {
        const stages = Object.keys(userSelectedFavouritesData?.exlude_all_category_constraints)
        for (let key of stages) {
          setExludeAllCategoryConstraints((prev) => {
            return {
              ...prev,
              [key]: userSelectedFavouritesData?.exlude_all_category_constraints?.[key]
            }
          })
        }
      }


      if (userSelectedFavouritesData?.is_cross_categorical) {
        const stages = Object.keys(userSelectedFavouritesData?.is_cross_categorical)
        for (let key of stages) {
          setIsCrossCategorical((prev) => {
            return {
              ...prev,
              [key]: userSelectedFavouritesData?.is_cross_categorical?.[key]
            }
          })
        }
      }
      if (userSelectedFavouritesData?.cross_categorical_selected_categories) {
        const stages = Object.keys(userSelectedFavouritesData?.cross_categorical_selected_categories)
        for (let key of stages) {
          setCrossCategoricalSelectedCategories((prev) => {
            return {
              ...prev,
              [key]: userSelectedFavouritesData?.cross_categorical_selected_categories?.[key]
            }
          })
        }
      }
      if (userSelectedFavouritesData?.is_categorical_constraints_use_default) {
        const stages = Object.keys(userSelectedFavouritesData?.is_categorical_constraints_use_default)
        for (let key of stages) {
          setIsCategoricalConstraintsUseDefault((prev) => {
            return {
              ...prev,
              [key]: userSelectedFavouritesData?.is_categorical_constraints_use_default?.[key]
            }
          })
        }
      }

      if (userSelectedFavouritesData?.cross_categorical_values) {
        const stages = Object.keys(userSelectedFavouritesData?.cross_categorical_values)
        for (let key of stages) {
          setCrossCategoricalValues((prev) => {
            return {
              ...prev,
              [key]: userSelectedFavouritesData?.cross_categorical_values?.[key]
            }
          })
        }
      }
      if (userSelectedFavouritesData?.inverse_configuration_data) {
        const stages = Object.keys(userSelectedFavouritesData?.inverse_configuration_data)
        for (let key of stages) {
          setInverseConfigurationData((prev) => {
            return {
              ...prev,
              [key]: userSelectedFavouritesData?.inverse_configuration_data?.[key]
            }
          })
        }
      }

      if (userSelectedFavouritesData?.catwise_range_parameter_list) {
        const stages = Object.keys(userSelectedFavouritesData?.catwise_range_parameter_list)
        for (let key of stages) {
          setCatwiseRangeParameterList((prev) => {
            return {
              ...prev,
              [key]: userSelectedFavouritesData?.catwise_range_parameter_list?.[key]
            }
          })
        }
      }

      if (userSelectedFavouritesData?.cat_constraints_list) {
        const stages = Object.keys(userSelectedFavouritesData?.cat_constraints_list)
        for (let key of stages) {
          setCatConstraintsList((prev) => {
            return {
              ...prev,
              [key]: userSelectedFavouritesData?.cat_constraints_list?.[key]
            }
          })
        }
      }

      setShowFavouritesDrawer((prev: any) => ({
        ...prev,
        selectedData: null
      }))
    }
  }, [showFavouritesDrawer, setShowFavouritesDrawer])


  const numericalObjectiveList: [string, any][] = useMemo(
    () =>
      Object.entries(
        (modelData?.output_range_optimal ?? modelData?.properties) || {},
      ).filter(([_, value]) => !Array.isArray(value)),
    [modelData],
  )

  const categoricalObjectiveList: CategoricalObjectiveList = useMemo(() => {
    if (!modelData) return []
    return Object.entries(
      modelData.output_range_optimal ?? modelData.properties ?? {},
    ).filter(([_, value]) => Array.isArray(value)) as CategoricalObjectiveList
  }, [modelData])

  useEffect(() => {
    if (statusRange === AsyncStates.SUCCESS) {
      setSelectedObjective("")
      setExperimentCurrent(1)
      inverseForm.resetFields()
    }
    // eslint-disable-next-line
  }, [statusRange])

  const stagesOptions = useMemo(() => {
    const options = new Array(
      selectedStages?.[selectedStages?.length - 1] - selectedStages?.[0] + 1,
    ).fill(null)
    const segmentOptions = options.map((res, index) => ({
      value: selectedStages[0] + index,
      label: modelConfigData[0]?.all_stages?.[selectedStages[0] + index - 1] ?? `${t("common.stage")} ${selectedStages[0] + index}`,
      title: modelConfigData[0]?.all_stages?.[selectedStages[0] + index - 1] ?? `Stage ${selectedStages[0] + index}`,
      disabled: false,
    }))
    return segmentOptions
  }, [selectedStages, t, modelConfigData])

  const clearState = useCallback(() => {
    setUnsavedChanges(false)
    setTitle('')
    setSelectedStages([1, 1])
    setCurrentSelectedStage(1)
    setActivePredictionInputTab({ 1: 0 })
    setRangeParameterList({ 1: [] })
    setCatwiseRangeParameterList({ 1: [] })
    setCategorialInputList({ 1: [] })
    setSelectedCharacterization({ 1: [] })
    setRange({
      1: [{
        parameter: "",
        output_range_optimal: `(0 - 0)`,
        min: "",
        max: "",
        checked: false,
        modelType: "",
        value: "",
        priority: null,
      }]
    })
    setCatConstraintsList({ 1: [] })
    setCategoricalRange({
      1: [
        {
          parameter: "",
          modelType: "range",
          output_range_optimal: `(0 - 0)`,
          val: [],
          checked: false,
        },
      ],
    })
  }, [setUnsavedChanges]);

  useEffect(() => {
    if (Boolean(modelVersion)) {
      setIsMultiStageModel(
        configData.find(
          (modelData: ModelConfig) =>
            modelData.version === Number(modelVersion),
        )?.is_multistage ?? false,
      )
      setSelectedStages([1, 1])
    }
    setCurrentSelectedStage(1)
    clearState()
  }, [modelVersion, dispatch, configData, clearState])

  const formatter = (value: number | undefined) => {
    const stageName = value !== undefined ? modelConfigData[0]?.all_stages?.[value - 1] : 0

    if (selectedStages[0] === selectedStages[1]) {
      return `Start and End Stage: ${stageName}`
    }
    if (selectedStages[0] === value) {
      return `Start Stage:  ${stageName}`
    }
    if (selectedStages[1] === value) {
      return `End Stage:  ${stageName}`
    }
    return value
  }

  const marks = useMemo(() => {
    return modelConfigData[0]?.all_stages?.reduce(
      (acc: any, slide: string, index: number) => {
        const stagePrefix = `Stage ${(index + 1)}`;
        const stageName = modelConfigData[0]?.all_stages?.[index];
        return {
          ...acc,
          [index + 1]: {
            label: (
              <>
                <Tooltip title={`${stagePrefix}: ${stageName}`}><Typography.Text>{stageName}</Typography.Text></Tooltip>
                {index + 1 < selectedStages[0] ||
                  index + 1 > selectedStages[1] ? (
                  <>
                    {" "}
                    <LockFilled />
                  </>
                ) : null}
              </>
            ),
            style: { width: "max-content" },
          },
        }
      },
      {},
    )
  }, [modelConfigData, selectedStages])

  // rangeParameterList, categorialInputList ===> ingredients and processes
  // numericalProperties, categoricalRange ===> properties
  const getcharacterizationPayload = useMemo(() => {
    return {
      ings_procs: [
        ...(!!rangeParameterList[currentSelectedStage]
          ? rangeParameterList[currentSelectedStage].map(
            (item) => item.parameter,
          )
          : []),
        ...(!!categorialInputList[currentSelectedStage]
          ? categorialInputList[currentSelectedStage].map(
            (item) => item.parameter,
          )
          : []),
      ],
      properties: [
        ...(!!numericalProperties[currentSelectedStage]
          ? numericalProperties?.[currentSelectedStage]?.map(
            (item) => item.parameter,
          )
          : []),
        ...(!!categoricalRange[currentSelectedStage]
          ? categoricalRange?.[currentSelectedStage]?.map(
            (item) => item.parameter,
          )
          : []),
      ].filter((item) => item),
    }
  }, [
    categorialInputList,
    categoricalRange,
    currentSelectedStage,
    numericalProperties,
    rangeParameterList,
  ])

  const handleCrossCategoricalConstraints = (stage: string) => {
    return {
      categories: crossCategoricalSelectedCategories?.[stage] ?? [],
      sum: {
        min: convertValue(crossCategoricalValues[stage]?.min) ?? null,
        max: convertValue(crossCategoricalValues[stage]?.max) ?? null,
      }
    }
  }

  const generateExp = (type: string) => {
    let otherProperties: { [key: string]: any } = {}
    let priority_list: { [key: string]: any } = {}
    let objectives: { [key: string]: any } = {}
    let category_wise_constraints: { [key: string]: any } = {}
    let variations: { [key: string]: any } = {}
    let characterization_method_id: { [key: string]: any } = {}
    let cross_category_constraints: { [key: string]: any } = {}
    // let use_default_category_constraints: { [key: string]: any } = {}
    let custom_inverse_configs: { [key: string]: any } = {}

    const correctedNumericalProperties: any = Object.entries(numericalProperties).reduce((acc, [stage, objectives]) => ({
      ...acc,
      [stage]: objectives.map(objective => ({
        ...objective,
        min: convertValue(objective.min),
        max: convertValue(objective.max)
      }))
    }), {})
    for (const stage of Object.keys(correctedNumericalProperties)) {
      const actualStageName = modelConfigData[0]?.all_stages?.[Number(stage) - 1];

      const combinedData = [
        ...correctedNumericalProperties[stage],
        ...categoricalRange[stage],
      ]
      const checkedProperties = combinedData.filter((res) => !!res.parameter)

      const inputsCheck = checkedProperties.filter((property) => {
        if (!isCategoricalProperties(property)) return true
        return !Array.isArray(property.val)
      })
      const optionsCheck = checkedProperties.filter((property) => {
        if (!isCategoricalProperties(property)) {
          return !property.modelType.length
        }

        return (
          !property.modelType.length ||
          (Array.isArray(property.val) && !property.val.length)
        )
      })

      // const customFeatureRangeData = [
      //   ...rangeParameterList[stage],
      //   ...categorialInputList[stage],
      // ]
      otherProperties[actualStageName] = optionsCheck.map(
        ({ parameter }) => parameter,
      )

      if (checkedProperties.length < 1 && stage === String(selectedStages[1]))
        return message.error(`${t("aiEngine.selectOneObjective")} ${isMultiStageModel ? `- ${actualStageName}` : ``}`)

      if (
        Boolean(configs?.ai_engine_with_methods) &&
        selectedCharacterization?.inverse?.variations?.length === 0
      ) {
        return message.error(
          `${t("common.selectAtleastOneCharacterizationVariation")} ${isMultiStageModel ? `- ${actualStageName}` : ``}`,
        )
      }

      if (
        checkedProperties.filter((property) => property.modelType).length < 1 &&
        stage === String(selectedStages[1])
      ) {
        return message.error(
          `Please select optimization type for at least one property ${isMultiStageModel ? `- ${actualStageName}` : ``}`,
        )
      }

      if (
        !Boolean(modelVersion) &&
        correctedNumericalProperties[stage].length > 0 &&
        correctedNumericalProperties[stage].some(
          (res: any) =>
            !!res.modelType.length &&
            res.modelType !== "range" &&
            !res.priority,
        ) &&
        stage === String(selectedStages[1])
      )
        return message.error(`Please Input Priority ${isMultiStageModel ? `- ${actualStageName}` : ``}`)

      if (
        inputsCheck.length > 0 &&
        stage === String(selectedStages[1]) &&
        inputsCheck.some((property) => {
          if (isCategoricalProperties(property)) return false
          return (
            property.modelType === "range" && (property.min === null || property.max === null)
          )
        })
      )
        return message.error(`${t("aiEngine.minMaxValuesCannotBeBlank")} ${isMultiStageModel ? `- ${actualStageName}` : ``}`)

      // if (
      //   Boolean(modelVersion) &&
      //   !!customFeatureRangeData?.length &&
      //   customFeatureRangeData.every(
      //     (res: any) => res?.max === null || res.min === null,
      //   )
      // )
      //   return message.error(`${t("aiEngine.minMaxValuesCannotBeBlank")} ${isMultiStageModel ? `- ${actualStageName}` : ``}`)

      if (
        !Object.values(catwiseRangeParameterList).length ||
        Object.values(catwiseRangeParameterList)
          .flat()[0]
          ?.categories.every((catObj: any) => catObj.exclude)
      ) {
        setActivePredictionInputTab((prev: any) => (
          {
            ...activePredictionInputTab,
            [stage]: 1
          }
        ))
        return message.error(`Include atleast 1 category ${isMultiStageModel ? `- ${actualStageName}` : ``}`)
      }

      priority_list[actualStageName] = checkedProperties.reduce(
        (obj, element) => {
          if (isCategoricalProperties(element)) return obj
          if (!element.priority) return obj

          return {
            ...obj,
            [element.parameter]: element.priority,
          }
        },
        {},
      )

      objectives[actualStageName] = {
        ...checkedProperties.reduce<{
          [key: string]: any
        }>((acc, curr) => {
          if (curr.modelType.length < 1) return acc
          if (otherProperties[actualStageName].includes(curr.parameter))
            return acc

          if (curr.modelType === "range") {
            acc[curr.modelType] = checkedProperties.reduce((obj, element) => {
              if (element.modelType !== curr.modelType) return obj
              if (otherProperties[actualStageName].includes(element.parameter))
                return acc

              const key = element.parameter

              return {
                ...obj,
                [key]: isCategoricalProperties(element)
                  ? element.val
                  : { min: element.min, max: element.max },
              }
            }, {})
          } else {
            acc["minmax"] = {
              ...acc["minmax"],
              ...checkedProperties.reduce((obj, element) => {
                if (element.modelType !== curr.modelType) return obj
                if (isCategoricalProperties(element)) return obj

                const key = element.parameter

                return {
                  ...obj,
                  [key]: element.value,
                }
              }, {}),
            }
          }
          return acc
        }, {}),
      }
console.log(catwiseRangeParameterList);
      category_wise_constraints[actualStageName] = {
        ...catwiseRangeParameterList[stage]
          .flatMap((typeObj: any) => typeObj.categories)
          .reduce(
            (acc: any, cur: any) => ({
              ...acc,
              [cur.category]: {
                ...cur,
                item_constraints: Object.entries(cur.item_constraints).reduce((acc, [param, constraint]: any) => ({
                  ...acc,
                  [param]: Array.isArray(constraint) ? constraint : {
                    ...constraint,
                    min: convertValue(constraint.min),
                    max: convertValue(constraint.max),
                    range: {
                      min: convertValue(constraint.min),
                      max: convertValue(constraint.max)
                    }
                  }
                }), {})
              },
            }),
            {},
          ),
      }

      cross_category_constraints[actualStageName] = handleCrossCategoricalConstraints(stage)

      // use_default_category_constraints[actualStageName] = isCategoricalConstraintsUseDefault[stage]

      custom_inverse_configs[actualStageName] = Object.keys(inverseConfigurationData?.[stage] ?? {}).reduce((acc, curr) => {
        return {
          ...acc,
          [curr]: inverseConfigurationData?.[stage]?.[curr]?.value ?? null
        }
      }, {})

      if (Boolean(configs?.ai_engine_with_methods)) {
        variations[actualStageName] =
          selectedCharacterization?.[stage].inverse?.variations

        characterization_method_id[actualStageName] =
          selectedCharacterization?.[stage].inverse?.characterization_id
      }
    }

    let catwisePayload: any = {
      token: jwtManager.getToken(),
      ...(Boolean(modelVersion) && { version: modelData.version }),
      ...(!Boolean(modelVersion) && { project_id: currentProject }),
      priority_list,
      ...(otherProperties.length > 0 && { other_properties: otherProperties }),
      objectives,
      ...(!!Object.values(catwiseRangeParameterList).flat().length && {
        category_wise_constraints,
      }),
      ...(Boolean(configs?.ai_engine_with_methods) && {
        variations,
        characterization_method_id,
      }),
      title,
      cross_category_constraints,
      // use_default_category_constraints,
      custom_inverse_configs
    }

    if (!isMultiStageModel) {
      const actualStageName = modelConfigData[0]?.all_stages?.[currentSelectedStage - 1];
      catwisePayload = {
        ...catwisePayload,
        priority_list: priority_list[actualStageName],
        objectives: objectives[actualStageName],
        cross_category_constraints: cross_category_constraints[actualStageName],
        // use_default_category_constraints: use_default_category_constraints[actualStageName],
        ...(otherProperties.length > 0 && {
          other_properties: otherProperties[actualStageName],
        }),
        ...(!!Object.values(catwiseRangeParameterList).flat().length && {
          category_wise_constraints:
            category_wise_constraints[actualStageName],
        }),
        ...(Boolean(configs?.ai_engine_with_methods) && {
          variations: variations[actualStageName],
          characterization_method_id:
            characterization_method_id[actualStageName],
        }),
      }
    }

    if (isMultiStageModel) {
      const start_stage = modelConfigData[0]?.all_stages?.[Number(Object.keys(correctedNumericalProperties)[0]) - 1] ?? `Stage ${Object.keys(correctedNumericalProperties)[0]}`
      const end_stage = modelConfigData[0]?.all_stages?.[Number(Object.keys(correctedNumericalProperties)[Object.keys(correctedNumericalProperties).length - 1]) - 1] ?? `Stage ${Object.keys(correctedNumericalProperties)[Object.keys(correctedNumericalProperties).length - 1]}`
      const catwisePayloadFinal = catwisePayload['objectives'][end_stage]
      catwisePayload['objectives'] = { [end_stage]: catwisePayloadFinal }
      catwisePayload["custom_inverse_configs"] = { [end_stage]: catwisePayload['custom_inverse_configs'][end_stage] }
      catwisePayload = {
        ...catwisePayload,
        start_stage,
        end_stage
      }
    }

    dispatch(clearSuggestedExpInsights())
    dispatch(
      newInverseModelRequest({
        payload: catwisePayload,
        isMultiStage: isMultiStageModel,
      }),
    )
  }

  const objectivesValidation = useCallback(() => {
    let isPassed = true
    let errorCount = 0
    const objectivesNumerical = numericalProperties[currentSelectedStage].map(res => ({
      ...res,
      min: convertValue(res.min),
      max: convertValue(res.max)
    }))
    const objectivesCategorical = categoricalRange[currentSelectedStage]


    const isNumericalObjectiveEmpty = objectivesNumerical.every((objective) => !objective?.parameter)

    const isCategoricalObjectiveEmpty = objectivesCategorical.every((objective) => !objective?.parameter)

    const setNumericalObjectivesErrors = ()=>{
      objectivesNumerical.forEach((objective) => {
        if (!objective?.parameter) {
          objective.error_parameter = t('aiEngine.inverse.errorObjective')
          isPassed = false
          errorCount++
        }

        if (!objective?.modelType) {
          objective.error_modelType = t('aiEngine.inverse.errorOptimization')
          isPassed = false
          errorCount++
        }

        if (objective?.modelType === "range" && (objective?.min === null)) {
          objective.error_min = t('aiEngine.inverse.errorMin')
          isPassed = false
          errorCount++
        }

        if (objective?.modelType === "range" && (objective?.max === null)) {
          objective.error_max = t('aiEngine.inverse.errorMax')
          isPassed = false
          errorCount++
        }

        if (objective?.modelType !== "range" && !objective?.priority) {
          objective.error_priority = t('aiEngine.inverse.errorPriority')
          isPassed = false
          errorCount++
        }

      })

      setRange((prevState: any) => {
        const newState = JSON.parse(JSON.stringify(prevState))
        newState[currentSelectedStage] = objectivesNumerical
        return { ...newState }
      })
    }

    const setCategoricalObjectivesErrors = ()=>{
      objectivesCategorical.forEach((objective) => {
        if (!objective?.parameter) {
          objective.error_parameter = t('aiEngine.inverse.errorObjective')
          isPassed = false
          errorCount++
        }

        if (!objective?.val?.length) {
          objective.error_val = t('aiEngine.inverse.errorValues')
          isPassed = false
          errorCount++
        }
      })

      setCategoricalRange((prevState: any) => {
        const newState = JSON.parse(JSON.stringify(prevState))
        newState[currentSelectedStage] = objectivesCategorical
        return { ...newState }
      })
    }

    const clearNumericalObjectivesErrors = ()=>{
      objectivesNumerical.forEach((objective) => {
        delete objective.error_parameter
        delete objective.error_modelType
        delete objective.error_min
        delete objective.error_max
        delete objective.error_priority
      })

      setRange((prevState: any) => {
        const newState = JSON.parse(JSON.stringify(prevState))
        newState[currentSelectedStage] = [{
          parameter: "",
          output_range_optimal: `(0 - 0)`,
          min: "",
          max: "",
          checked: false,
          modelType: "",
          value: "",
          priority: null,
        }]
        return { ...newState }
      })
    }

    const clearCategoricalObjectivesErrors = ()=>{
      objectivesCategorical.forEach((objective) => {
        delete objective.error_parameter
        delete objective.error_val
      })

      setCategoricalRange((prevState: any) => {
        const newState = JSON.parse(JSON.stringify(prevState))
        newState[currentSelectedStage] = [{
          parameter: "",
          modelType: "range",
          output_range_optimal: `(0 - 0)`,
          val: [],
          checked: false,
        }]
        return { ...newState }
      })
    }

    if (!isNumericalObjectiveEmpty) {
      setNumericalObjectivesErrors()
      isCategoricalObjectiveEmpty && clearCategoricalObjectivesErrors()
    }

    if (categoricalObjectiveList.length > 0 && 
      (!isCategoricalObjectiveEmpty)) {
      setCategoricalObjectivesErrors() 
      isNumericalObjectiveEmpty && clearNumericalObjectivesErrors()
    }

    // Display Error count via Notifications
    if(isNumericalObjectiveEmpty && isCategoricalObjectiveEmpty){
      setNumericalObjectivesErrors()
      setCategoricalObjectivesErrors()
      isPassed = false
      notification.error({
        message: t('aiEngine.selectAtLeastOneObjective'),
        duration: 2
      })
    }

    errorCount && notification.error({
      message: `${errorCount} ${t('common.Errors')}`,
      duration: 2
    })
    
    return isPassed
  }, [categoricalObjectiveList.length, categoricalRange, convertValue, currentSelectedStage, numericalProperties, t])

  const constraintsValidation = useCallback(() => {
    let isPassed = true
    let errorCount = 0

    const categoricalConstraintsList = catConstraintsList?.[currentSelectedStage]?.map((res: any) => res)
    const parametersList = rangeParameterList?.[currentSelectedStage]?.map((res: any) => res)

    categoricalConstraintsList?.forEach((constraint: any) => {
      if (constraint?.error_min_additional) {
        isPassed = false
        errorCount++
      }else{
        delete constraint.error_min
      }
      delete constraint.error_max
    })

    parametersList?.forEach((param: any) => {
      delete param.error_min
      delete param.error_max
      if (param?.parameter === "") {
        param.error_parameter = t('aiEngine.inverse.errorObjective')
        isPassed = false
        errorCount++
      }
    })

    setRangeParameterList((prevState: any) => {
      const newState = JSON.parse(JSON.stringify(prevState))
      newState[currentSelectedStage] = parametersList
      return { ...newState }
    })
    errorCount && message.error(`${errorCount} ${t('common.Errors')}`)
    return isPassed
  }, [catConstraintsList, currentSelectedStage, rangeParameterList, t])

  const validationsForSteps = useCallback(() => {
    const currentStep = activePredictionInputTab[currentSelectedStage]
    if (currentStep === OBJECTIVES_STEP_NUMBER) {
      return objectivesValidation()
    }

    if (currentStep === CONSTRAINTS_STEP_NUMBER) {
      return constraintsValidation()
    }
  }, [activePredictionInputTab, constraintsValidation, currentSelectedStage, objectivesValidation])

  const getDisplayNames = useCallback(
    (key: string) => {
      return labels?.properties?.[key]?.name ?? key
    },
    [labels?.properties],
  )

  const optionsDropDown = (rangeData: any, index: number) => {
    const errorMessage = rangeData?.error_val
    return (
      <div style={{ padding: PADDING_TOP_FOR_DATASHEET_CELLS / 2, paddingTop: PADDING_TOP_FOR_DATASHEET_CELLS, paddingBottom: 0, display: 'flex', flexDirection: 'column', gap: '0rem', alignItems: 'flex-start', flexGrow: 1 }}>
        <Select
          style={{ width: "100%" }}
          value={
            rangeData?.val
          }
          disabled={!categoricalRange[currentSelectedStage][index]?.parameter}
          mode="multiple"
          onChange={(e) => {
            setCategoricalRange((prevState) => {
              const newState = JSON.parse(JSON.stringify(prevState))
              newState[currentSelectedStage][index].val = e
              delete newState[currentSelectedStage][index].error_val
              return { ...newState }
            })
          }}
        >
          {(modelData?.output_range_optimal ?? modelData?.properties)?.[
            rangeData?.parameter
          ]?.map((res: string) => (
            <Option
              key={typeof res === "boolean" ? String(res) : res}
              value={typeof res === "boolean" ? String(res) : res}
            >
              {typeof res === "boolean" ? String(res) : res}
            </Option>
          ))}
        </Select>
        <ErrorText text={errorMessage ? errorMessage : ''} />
      </div>
    )
  }

  const disableCell = useCallback(
    (record: any, type: string) => {
      const result = numericalProperties[currentSelectedStage].find(
        (res) => res.parameter === record.parameter,
      )
      if (
        result?.modelType &&
        result.modelType.length > 0 &&
        result.modelType === type
      ) {
        return false
      } else {
        return true
      }
    },
    [currentSelectedStage, numericalProperties],
  )

  const addRow = (inputType: string) => {
    if (inputType === "properties") {
      setRange((state) => {
        const newState = JSON.parse(JSON.stringify(state))

        if (newState[currentSelectedStage]) {
          newState[currentSelectedStage].push({
            parameter: "",
            output_range_optimal: `(0 - 0)`,
            min: "",
            max: "",
            checked: false,
            modelType: "",
            value: "",
            priority: null,
          })
        } else {
          newState[currentSelectedStage] = [
            {
              parameter: "",
              output_range_optimal: `(0 - 0)`,
              min: "",
              max: "",
              checked: false,
              modelType: "",
              value: "",
              priority: null,
            },
          ]
        }

        return {
          ...newState,
        }
      })
    } else {
      setCategoricalRange((state) => {
        const newState = JSON.parse(JSON.stringify(state))

        if (newState[currentSelectedStage]) {
          newState[currentSelectedStage].push({
            parameter: "",
            modelType: "range",
            output_range_optimal: `(0 - 0)`,
            val: [],
            checked: false,
          })
        } else {
          newState[currentSelectedStage] = [
            {
              parameter: "",
              modelType: "range",
              output_range_optimal: `(0 - 0)`,
              val: [],
              checked: false,
            },
          ]
        }
        return {
          ...newState,
        }
      })
    }
  }

  const removeRow = useCallback(
    (parameterIndex: number, constraintstype: string) => {
      if (constraintstype === "numerical_output_constraints") {
        setRange((state) => {
          const newState = JSON.parse(JSON.stringify(state))
          if (newState[currentSelectedStage].length > 1) {
            newState[currentSelectedStage] = [
              ...newState[currentSelectedStage].filter(
                (_: NumericalProperty, index: number) =>
                  index !== parameterIndex,
              ),
            ]
          } else {
            newState[currentSelectedStage] = [
              {
                parameter: "",
                output_range_optimal: `(0 - 0)`,
                min: "",
                max: "",
                checked: false,
                modelType: "",
                value: "",
                priority: null,
              },
            ]
          }
          return { ...newState }
        })
      } else {
        setCategoricalRange((state) => {
          const newState = JSON.parse(JSON.stringify(state))
          if (newState[currentSelectedStage].length > 1) {
            newState[currentSelectedStage] = [
              ...newState[currentSelectedStage].filter(
                (_: CategoricalProperty, index: number) =>
                  index !== parameterIndex,
              ),
            ]
          } else {
            newState[currentSelectedStage] = [
              {
                parameter: "",
                modelType: "range",
                output_range_optimal: `(0 - 0)`,
                val: [],
                checked: false,
              },
            ]
          }
          return { ...newState }
        })
      }
    },
    [currentSelectedStage],
  )

  const parameterListDropDown = useCallback(
    (index: any, constraintstype: string) => {
      const errorMessage = constraintstype === "numerical_output_constraints" ? numericalProperties[currentSelectedStage][index]?.error_parameter : categoricalRange[currentSelectedStage][index]?.error_parameter
      if (constraintstype === "numerical_output_constraints") {
        return (
          <Row
            style={{
              alignItems: "center",
              width: "100%",
              padding: 0,
              justifyContent: "space-between",
              gap: 8,
              flexWrap: "nowrap",
            }}
          >
            <Tooltip title={t("common.remove")}>
              <StyledDeleteIcon
                style={{
                  fontSize: antdTheme.fontSizeHeading4,
                  verticalAlign: "baseline",
                  outline: "none",
                }}
                onClick={() => removeRow(index, constraintstype)}
              />
            </Tooltip>
            <div style={{ padding: PADDING_TOP_FOR_DATASHEET_CELLS / 2, paddingTop: PADDING_TOP_FOR_DATASHEET_CELLS, paddingBottom: 0, display: 'flex', flexDirection: 'column', gap: '0rem', alignItems: 'flex-start', flexGrow: 1 }}>
              <Select
                value={
                  getDisplayNames(
                    numericalProperties[currentSelectedStage]?.[index]?.parameter,
                  ) ||
                  numericalProperties[currentSelectedStage]?.[index]?.parameter
                }
                {...errorMessage && { status: "error" }}
                showSearch
                style={{
                  flexGrow: 1,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  verticalAlign: "bottom",
                  width: "100%",
                }}
                optionFilterProp="children"
                filterOption={(input, option: any) => {
                  return (option?.children ?? "")
                    ?.toLowerCase()
                    ?.includes(input.toLowerCase())
                }}
                notFoundContent={
                  <Space style={{ background: "whitesmoke", width: "100%" }}>
                    <Text>
                      {!numericalObjectiveList.length
                        ? "NO OBJECTIVES FOUND"
                        : "You have selected all the parameters."}
                    </Text>
                  </Space>
                }
                dropdownRender={(menu) => {
                  return (
                    <>
                      {!!numericalObjectiveList.length && (
                        <Checkbox
                          style={{ padding: 10 }}
                          checked={
                            numericalProperties[currentSelectedStage].filter(
                              (numerical) => numerical.parameter,
                            ).length === numericalObjectiveList.length
                          }
                          onChange={(e) => {
                            if (e.target.checked) {
                              const newRange = numericalObjectiveList?.map(
                                ([ele, value]) => ({
                                  parameter: ele,
                                  output_range_optimal: `(${getValue(value?.min)} - ${getValue(value?.max)})`,
                                  min: "",
                                  max: "",
                                  checked: false,
                                  modelType: "",
                                  value: "",
                                  priority: null,
                                }),
                              )
                              setRange((prevState) => ({
                                ...prevState,
                                [currentSelectedStage]: newRange,
                              }))
                            } else {
                              setRange((prevState) => {
                                const newState = JSON.parse(
                                  JSON.stringify(prevState),
                                )
                                newState[currentSelectedStage] = [
                                  {
                                    parameter: "",
                                    output_range_optimal: `(0 - 0)`,
                                    min: "",
                                    max: "",
                                    checked: false,
                                    modelType: "",
                                    value: "",
                                    priority: null,
                                  },
                                ]
                                return { ...newState }
                              })
                            }
                          }}
                        >{`${t("common.selectAll")}`}</Checkbox>
                      )}
                      {menu}
                    </>
                  )
                }}
                onSelect={(e: any) => {
                  const newRange = [...numericalProperties[currentSelectedStage]]
                  newRange[index].parameter = e
                  delete newRange[index].error_parameter
                  delete newRange[index].error_modelType
                  delete newRange[index].error_min
                  delete newRange[index].error_max
                  delete newRange[index].error_priority
                  newRange[index].output_range_optimal = `(${getValue((modelData?.output_range_optimal ?? modelData?.properties)?.[
                    e
                  ]?.min)
                    } - ${getValue((modelData?.output_range_optimal ?? modelData?.properties)?.[
                      e
                    ]?.max)
                    })`

                  newRange[index].min = ""
                  newRange[index].max = ""
                  newRange[index].checked = false
                  newRange[index].modelType = ""
                  newRange[index].value = ""
                  newRange[index].priority = null
                  setRange((prevState) => ({
                    ...prevState,
                    [currentSelectedStage]: newRange,
                  }))
                  setUnsavedChanges(true)
                }}
                placeholder={t("aiEngine.inverseModel.selectType")}
              >
                {numericalObjectiveList
                  ?.filter(
                    ([key]) =>
                      !numericalProperties[currentSelectedStage]
                        .map((row) => row?.parameter)
                        .includes(key),
                  )
                  .map(([res]) => (
                    <Option value={res} key={res}>
                      {getDisplayNames(res)}
                    </Option>
                  ))}
              </Select>
              <ErrorText text={errorMessage ? errorMessage : ''} />
            </div>
          </Row>
        )
      } else {
        return (
          <Row
            style={{
              alignItems: "center",
              width: "100%",
              padding: 0,
              justifyContent: "space-between",
              gap: 8,
              flexWrap: "nowrap",
            }}
          >
            <Tooltip title={t("common.remove")}>
              <StyledDeleteIcon
                style={{
                  fontSize: antdTheme.fontSizeHeading4,
                  verticalAlign: "baseline",
                  outline: "none",
                }}
                onClick={() => removeRow(index, constraintstype)}
              />
            </Tooltip>

            <div style={{ padding: PADDING_TOP_FOR_DATASHEET_CELLS / 2, paddingTop: PADDING_TOP_FOR_DATASHEET_CELLS, paddingBottom: 0, display: 'flex', flexDirection: 'column', gap: '0rem', alignItems: 'flex-start', flexGrow: 1 }}>
              <Select
                value={
                  getDisplayNames(
                    categoricalRange[currentSelectedStage]?.[index]?.parameter,
                  ) ?? categoricalRange[currentSelectedStage]?.[index]?.parameter
                }
                {...errorMessage && { status: "error" }}
                style={{
                  flexGrow: 1,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  verticalAlign: "bottom",
                  width: "100%",
                }}
                optionFilterProp="children"
                filterOption={(input, option: any) => {
                  return (option?.children ?? "")
                    ?.toLowerCase()
                    ?.includes(input.toLowerCase())
                }}
                notFoundContent={
                  <Space style={{ background: "whitesmoke", width: "100%" }}>
                    <Text>
                      {!categoricalObjectiveList.length
                        ? "NO OBJECTIVES FOUND"
                        : "You have selected all the parameters."}
                    </Text>
                  </Space>
                }
                dropdownRender={(menu) => {
                  return (
                    <>
                      {!!Object.keys(
                        (modelData?.output_range_optimal ??
                          modelData?.properties) ||
                        {},
                      ).length && (
                          <Checkbox
                            style={{ padding: 10 }}
                            checked={
                              categoricalObjectiveList.length ===
                              categoricalRange[currentSelectedStage].filter(
                                (categorical: any) => categorical?.parameter,
                              )?.length
                            }
                            onChange={(e) => {
                              if (e.target.checked) {
                                const newCategoricalRange = categoricalObjectiveList
                                  ?.filter(
                                    ([key]) =>
                                      !categoricalRange[currentSelectedStage]
                                        .map((row) => row?.parameter)
                                        .includes(key),
                                  )
                                  .map(([ele, value]) => ({
                                    parameter: ele,
                                    modelType: "range",
                                    output_range_optimal: `${value}`,
                                    val: [],
                                    checked: false,
                                  }))
                                setCategoricalRange((prevState) => ({
                                  ...prevState,
                                  [currentSelectedStage]: newCategoricalRange,
                                }))
                              } else {
                                setCategoricalRange((prevState) => {
                                  const newState = JSON.parse(
                                    JSON.stringify(prevState),
                                  )

                                  newState[currentSelectedStage] = [
                                    {
                                      parameter: "",
                                      modelType: "range",
                                      output_range_optimal: `(0 - 0)`,
                                      val: [],
                                      checked: false,
                                    },
                                  ]
                                  return { ...newState }
                                })
                              }
                            }}
                          >{`${t("common.selectAll")}`}</Checkbox>
                        )}
                      {menu}
                    </>
                  )
                }}
                onSelect={(e: any) => {
                  const newCategoricalRange = [
                    ...categoricalRange[currentSelectedStage],
                  ]
                  newCategoricalRange[index].parameter = e
                  delete newCategoricalRange[index].error_parameter
                  delete newCategoricalRange[index].error_val
                  newCategoricalRange[
                    index
                  ].output_range_optimal = `${(modelData?.output_range_optimal ??
                    modelData?.properties)?.[e]?.join(", ")}`
                  newCategoricalRange[index].modelType = "range"
                  newCategoricalRange[index].val = []
                  newCategoricalRange[index].checked = false
                  setCategoricalRange((prevState) => ({
                    ...prevState,
                    [currentSelectedStage]: newCategoricalRange,
                  }))
                  setUnsavedChanges(true)
                }}
                placeholder={t("aiEngine.inverseModel.selectType")}
              >
                {categoricalObjectiveList
                  ?.filter(
                    ([key]) =>
                      !categoricalRange[currentSelectedStage]
                        .map((row: any) => row?.parameter)
                        .includes(key),
                  )
                  .map(([res]) => (
                    <Option value={res} key={res}>
                      {getDisplayNames(res) ?? { res }}
                    </Option>
                  ))}
              </Select>
              <ErrorText text={errorMessage ? errorMessage : ''} />
            </div>
          </Row>
        )
      }
    },
    [
      t,
      getDisplayNames,
      numericalProperties,
      currentSelectedStage,
      numericalObjectiveList,
      removeRow,
      modelData?.output_range_optimal,
      modelData?.properties,
      setUnsavedChanges,
      categoricalRange,
      categoricalObjectiveList,
      getValue
    ],
  )

  useEffect(() => {
    if (newInverseModel.inverseStatus === AsyncStates.ERROR) {
      dispatch(suggestedExperimentsClear())
    }
  }, [newInverseModel, dispatch])

  // On stages selection change moving to last stage objective

  useEffect(() => {
    // if(validationsForSteps()){
    // setCurrentSelectedStage(selectedStages[1])
    // }
    setActivePredictionInputTab((prev: any) => (
      {
        ...prev,
        [selectedStages[1]]: 0,
      }
    ))

    const stage = selectedStages[1]
    if (!Object.keys(modelConfigData[stage - 1] || {}).length && Boolean(modelVersion)) {
      dispatch(
        modelsConfigRequest({
          version: modelVersion,
          isMultiStage: isMultiStageModel,
          stage_name: modelConfigData?.[0]?.all_stages?.[stage - 1],
        }),
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, selectedStages])

  return (
    <Space
      direction="vertical"
      style={{
        width: "100%",
      }}
      size="middle"
    >

      {/* Title */}

      <div style={{
        width: '100%',
        display: 'flex',
        gap: '1rem',
        alignItems: 'center',
      }} >
        <Text strong style={{
          wordBreak: 'keep-all',
          flexGrow: 1,
          flexShrink: 0
        }} >{t("aiEngine.inverse.predictionTitle")}: </Text>
        <Input
          onChange={(e) => setTitle(e.target.value)}
          value={title}
          placeholder={t("aiEngine.briefDescription")}
          style={{
            flexGrow: 0,
            flexShrink: 1
          }}
        />
      </div>
      {/* Slider */}
      {Boolean(modelVersion) && isMultiStageModel && (
        <Space size={"large"}>
          <Typography.Text>
            {t("aiEngine.selectStagesForOptimization")}
            {": "}
          </Typography.Text>
          <Slider
            tooltip={{ formatter }}
            style={{
              margin: "1rem",
              transition: "all .5s",
              width: (modelConfigData[0]?.all_stages?.length ?? 1) * 80,
              maxWidth: "90%",
            }}
            value={selectedStages}
            range
            marks={marks}
            step={null}
            min={1}
            max={modelConfigData[0]?.all_stages?.length ?? 1}
            onChange={(val: number[]) => {
              setSelectedStages((prev: [number, number]) => {
                if (prev[0] !== val[0]) {
                  if (val[0] > prev[0]) {
                    if (
                      val[0] >= currentSelectedStage &&
                      prev[1] >= currentSelectedStage
                    ) {
                      if (!Object.keys(modelConfigData[val[0] - 1] || {}).length) {
                        dispatch(
                          modelsConfigRequest({
                            version: modelVersion,
                            isMultiStage: isMultiStageModel,
                            stage_name:
                              modelConfigData?.[0]?.all_stages?.[val[0] - 1],
                          }),
                        )
                      }
                      setCurrentSelectedStage(val[0])
                    }
                  }
                } else {
                  if (val[1] < prev[1]) {
                    if (
                      !(
                        currentSelectedStage >= prev[0] &&
                        currentSelectedStage <= val[1]
                      )
                    ) {
                      if (!Object.keys(modelConfigData[val[1] - 1] || {}).length) {
                        dispatch(
                          modelsConfigRequest({
                            version: modelVersion,
                            isMultiStage: isMultiStageModel,
                            stage_name:
                              modelConfigData?.[0]?.all_stages?.[val[1] - 1],
                          }),
                        )
                      }
                      setCurrentSelectedStage(val[1])
                    }
                  }
                }
                return [val[0], val[1]]
              })

              setRange((state) => {
                const newState = JSON.parse(JSON.stringify(state))
                const stagesToKeep: number[] = []
                const finalState: { [key: number]: any } = {}

                for (let stage = val[0]; stage <= val[1]; stage++) {
                  stagesToKeep.push(stage)
                  if (!newState[stage]) {
                    newState[stage] = [
                      {
                        parameter: "",
                        output_range_optimal: `(0 - 0)`,
                        min: "",
                        max: "",
                        checked: false,
                        modelType: "",
                        value: "",
                        priority: null,
                      },
                    ]
                  }
                }

                for (const stage of stagesToKeep) {
                  finalState[stage] = newState[stage]
                }
                return {
                  ...finalState,
                }
              })
              setRangeParameterList((state) => {
                const newState = JSON.parse(JSON.stringify(state))
                const stagesToKeep: number[] = []
                const finalState: { [key: number]: any } = {}

                for (let stage = val[0]; stage <= val[1]; stage++) {
                  stagesToKeep.push(stage)
                  if (!newState[stage]) {
                    newState[stage] = []
                  }
                }

                for (const stage of stagesToKeep) {
                  finalState[stage] = newState[stage]
                }
                return {
                  ...finalState,
                }
              })
              setCatwiseRangeParameterList((state) => {
                const newState = JSON.parse(JSON.stringify(state))
                const stagesToKeep: number[] = []
                const finalState: { [key: number]: any } = {}

                for (let stage = val[0]; stage <= val[1]; stage++) {
                  stagesToKeep.push(stage)
                  if (!newState[stage]) {
                    newState[stage] = []
                  }
                }

                for (const stage of stagesToKeep) {
                  finalState[stage] = newState[stage]
                }
                return {
                  ...finalState,
                }
              })
              setCategorialInputList((state) => {
                const newState = JSON.parse(JSON.stringify(state))
                const stagesToKeep: number[] = []
                const finalState: { [key: number]: any } = {}

                for (let stage = val[0]; stage <= val[1]; stage++) {
                  stagesToKeep.push(stage)
                  if (!newState[stage]) {
                    newState[stage] = []
                  }
                }

                for (const stage of stagesToKeep) {
                  finalState[stage] = newState[stage]
                }
                return {
                  ...finalState,
                }
              })
              setSelectedCharacterization((state) => {
                const newState = JSON.parse(JSON.stringify(state))
                const stagesToKeep: number[] = []
                const finalState: { [key: number]: any } = {}

                for (let stage = val[0]; stage <= val[1]; stage++) {
                  stagesToKeep.push(stage)
                  if (!newState[stage]) {
                    newState[stage] = { inverse: {}, forward: {} }
                  }
                }

                for (const stage of stagesToKeep) {
                  finalState[stage] = newState[stage]
                }
                return {
                  ...finalState,
                }
              })
              setCategoricalRange((state) => {
                const newState = JSON.parse(JSON.stringify(state))
                const stagesToKeep: number[] = []
                const finalState: { [key: number]: any } = {}

                for (let stage = val[0]; stage <= val[1]; stage++) {
                  stagesToKeep.push(stage)
                  if (!newState[stage]) {
                    newState[stage] = [
                      {
                        parameter: "",
                        modelType: "range",
                        output_range_optimal: `(0 - 0)`,
                        val: [],
                        checked: false,
                      },
                    ]
                  }
                }

                for (const stage of stagesToKeep) {
                  finalState[stage] = newState[stage]
                }
                return {
                  ...finalState,
                }
              })
              setIsCrossCategorical((state) => {
                const newState = JSON.parse(JSON.stringify(state))
                const stagesToKeep: number[] = []
                const finalState: { [key: number]: any } = {}

                for (let stage = val[0]; stage <= val[1]; stage++) {
                  stagesToKeep.push(stage)
                  if (!newState[stage]) {
                    newState[stage] = false
                  }
                }

                for (const stage of stagesToKeep) {
                  finalState[stage] = newState[stage]
                }
                return {
                  ...finalState,
                }
              })

              setIsCategoricalConstraintsUseDefault((state) => {
                const newState = JSON.parse(JSON.stringify(state))
                const stagesToKeep: number[] = []
                const finalState: { [key: number]: any } = {}

                for (let stage = val[0]; stage <= val[1]; stage++) {
                  stagesToKeep.push(stage)
                  if (!newState[stage]) {
                    newState[stage] = false
                  }
                }

                for (const stage of stagesToKeep) {
                  finalState[stage] = newState[stage]
                }
                return {
                  ...finalState,
                }
              })

              setCrossCategoricalSelectedCategories((state) => {
                const newState = JSON.parse(JSON.stringify(state))
                const stagesToKeep: number[] = []
                const finalState: { [key: number]: any } = {}

                for (let stage = val[0]; stage <= val[1]; stage++) {
                  stagesToKeep.push(stage)
                  if (!newState[stage]) {
                    newState[stage] = []
                  }
                }

                for (const stage of stagesToKeep) {
                  finalState[stage] = newState[stage]
                }
                return {
                  ...finalState,
                }
              })

              setCrossCategoricalValues((state) => {
                const newState = JSON.parse(JSON.stringify(state))
                const stagesToKeep: number[] = []
                const finalState: crossCategoricalValuesType = {}

                for (let stage = val[0]; stage <= val[1]; stage++) {
                  stagesToKeep.push(stage)
                  if (!newState[stage]) {
                    newState[stage] = { min: null, max: null }
                  }
                }

                for (const stage of stagesToKeep) {
                  finalState[stage] = newState[stage]
                }
                return {
                  ...finalState,
                }

              })

              setInverseConfigurationData((state: any) => {
                const newState = JSON.parse(JSON.stringify(state))
                const stagesToKeep: number[] = []
                const finalState: { [key: number]: any } = {}

                for (let stage = val[0]; stage <= val[1]; stage++) {
                  stagesToKeep.push(stage)
                  if (!newState[stage]) {
                    newState[stage] = defaultInverseConfigurationData
                  }
                }

                for (const stage of stagesToKeep) {
                  finalState[stage] = newState[stage]
                }

                return {
                  ...finalState,
                }
              })
            }}
          />
        </Space>
      )}

      {/* Stage tabs and main content */}
      <Space direction="vertical" style={{ width: "100%", paddingBottom: '2rem' }} >
        {Boolean(modelVersion) && isMultiStageModel && (
          <Segmented
            options={stagesOptions}
            value={currentSelectedStage}
            style={{ transition: "all .5s" }}
            size="middle"
            onChange={(stage: any) => {
              if (!Object.keys(modelConfigData[stage - 1] || {}).length) {
                dispatch(
                  modelsConfigRequest({
                    version: modelVersion,
                    isMultiStage: isMultiStageModel,
                    stage_name: modelConfigData?.[0]?.all_stages?.[stage - 1],
                  }),
                )
              }
              if (validationsForSteps()) {
                setCurrentSelectedStage(stage)
              }
            }}
            className="inverse-stage-segmented"
          />
        )}

        {Boolean(modelVersion) && !!currentStageModelConfigData?.missing_properties?.length ? <DroppedPropertyWarning missingProperties={currentStageModelConfigData?.missing_properties} /> : null}


        <StyledCard
          style={{ width: "100%", paddingTop: '1rem' }}
          bodyStyle={{ padding: 0 }}
          headStyle={{ padding: 0 }}
        >
          {Boolean(configs?.ai_engine_with_methods) && (
            <ZeonCharacterizationSelection
              selectedCharacterization={selectedCharacterization}
              setSelectedCharacterization={setSelectedCharacterization}
              ings_procs={getcharacterizationPayload.ings_procs}
              properties={getcharacterizationPayload.properties}
              tab={tab}
              currentSelectedStage={currentSelectedStage}
            />
          )}
          <div style={{ width: "100%" }}>
            <PayloadInput
              modelData={modelData}
              addRow={addRow}
              disableCell={disableCell}
              numericalObjectiveList={numericalObjectiveList}
              parameterListDropDown={parameterListDropDown}
              setUnsavedChanges={setUnsavedChanges}
              numericalProperties={numericalProperties[currentSelectedStage]}
              setRange={setRange}
              currentSelectedStage={currentSelectedStage}
              isMultiStageModel={isMultiStageModel}
              categoricalObjectiveList={categoricalObjectiveList}
              categoricalRange={categoricalRange[currentSelectedStage]}
              optionsDropDown={optionsDropDown}
              rangeParameterList={rangeParameterList[currentSelectedStage]}
              setRangeParameterList={setRangeParameterList}
              catwiseRangeParameterList={
                catwiseRangeParameterList[currentSelectedStage]
              }
              setCatwiseRangeParameterList={setCatwiseRangeParameterList}
              categorialInputList={categorialInputList[currentSelectedStage]}
              setCategorialInputList={setCategorialInputList}
              activePredictionInputTab={activePredictionInputTab}
              setActivePredictionInputTab={setActivePredictionInputTab}
              selectedStages={selectedStages}
              catConstraintsList={catConstraintsList[currentSelectedStage]}
              setCatConstraintsList={setCatConstraintsList}
              modelVersion={modelVersion}
              setSelectAllIngredientsInCategory={setSelectAllIngredientsInCategory}
              selectAllIngredientsInCategory={selectAllIngredientsInCategory[currentSelectedStage]}
              exludeAllParameterConstraints={exludeAllParameterConstraints[currentSelectedStage]}
              setExludeAllParameterConstraints={setExludeAllParameterConstraints}
              exludeAllCategoryConstraints={exludeAllCategoryConstraints[currentSelectedStage]}
              setExludeAllCategoryConstraints={setExludeAllCategoryConstraints}
              isCrossCategorical={isCrossCategorical}
              setIsCrossCategorical={setIsCrossCategorical}
              setIsCrossCategoricalModalVisible={setIsCrossCategoricalModalVisible}
              isCategoricalConstraintsUseDefault={isCategoricalConstraintsUseDefault}
              setIsCategoricalConstraintsUseDefault={setIsCategoricalConstraintsUseDefault}
              inverseConfigurationData={inverseConfigurationData}
              setInverseConfigurationData={setInverseConfigurationData}
              validationsForSteps={validationsForSteps}
              configsOpenedViaPredictButton={configsOpenedViaPredictButton}
              setConfigsOpenedViaPredictButton={setConfigsOpenedViaPredictButton}
              generateExp={generateExp}
            />
          </div>
        </StyledCard>
      </Space>

      {/* Bottom buttons */}
      <BottomButtons
        generateExp={generateExp}
        setUnsavedChanges={setUnsavedChanges}
        newInverseModel={newInverseModel}
        clearState={clearState}
        currentSelectedStage={currentSelectedStage}
        activePredictionInputTab={activePredictionInputTab}
        setActivePredictionInputTab={setActivePredictionInputTab}
        selectedStages={selectedStages}
        setCurrentSelectedStage={setCurrentSelectedStage}
        validationsForSteps={validationsForSteps}
        setConfigsOpenedViaPredictButton={setConfigsOpenedViaPredictButton}
        isMultiStageModel={isMultiStageModel}
        modelConfigData={modelConfigData}
      />

      <CrossCategoricalModal
        open={isCrossCategoricalModalVisible}
        setIsCrossCategoricalModalVisible={setIsCrossCategoricalModalVisible}

        crossCategoricalSelectedCategories={crossCategoricalSelectedCategories}
        setCrossCategoricalSelectedCategories={setCrossCategoricalSelectedCategories}

        crossCategoricalValues={crossCategoricalValues}
        setCrossCategoricalValues={setCrossCategoricalValues}

        currentSelectedStage={currentSelectedStage}

        catwiseParameterList={catwiseRangeParameterList[currentSelectedStage]}
        isMultiStageModel={isMultiStageModel}
      />

    </Space >
  )
}

const CrossCategoricalModal = ({
  open,
  setIsCrossCategoricalModalVisible,

  crossCategoricalSelectedCategories,
  setCrossCategoricalSelectedCategories,

  crossCategoricalValues,
  setCrossCategoricalValues,

  currentSelectedStage,

  catwiseParameterList,
  isMultiStageModel
}: any) => {
  const [t] = useTranslate()
  const { convertValue } = useValue()

  const options = useMemo(() => {
    return (catwiseParameterList || [])
      ?.filter((typeObj: any) => typeObj.parameter_type === "ingredients")
      ?.flatMap((typeObj: any) => {
        return typeObj?.categories?.map((item: any) => {
          return (
            <Option value={item?.category} key={item?.category}>
              {item?.category}
            </Option>
          )
        })
      })
  }, [catwiseParameterList])

  const handleClear = useCallback(() => {
    setCrossCategoricalSelectedCategories((state: any) => {
      const newState = JSON.parse(JSON.stringify(state))
      newState[currentSelectedStage] = []
      return { ...newState }
    })
    setCrossCategoricalValues((state: any) => {
      const newState = JSON.parse(JSON.stringify(state))
      newState[currentSelectedStage] = { min: null, max: null }
      return { ...newState }
    })
  }, [currentSelectedStage, setCrossCategoricalSelectedCategories, setCrossCategoricalValues])

  const getCrossCategoryContraintValue = (input: any) => {
    if (isValidNumber(input)) {
      return input
    } else {
      const inputValue = input.trim()
      return inputValue === "" ? null : inputValue
    }
  }

  const handleCancel = useCallback(() => {
    if (isMultiStageModel && !!crossCategoricalSelectedCategories[currentSelectedStage]?.length) {
      if (crossCategoricalValues[currentSelectedStage]?.min === null) {
        return message.error("Please Enter Min Quantity")
      }
      if (crossCategoricalValues[currentSelectedStage]?.max === null) {
        return message.error("Please Enter Max Quantity")
      }
    }
    setIsCrossCategoricalModalVisible(false)
  }, [crossCategoricalSelectedCategories, crossCategoricalValues, currentSelectedStage, isMultiStageModel, setIsCrossCategoricalModalVisible])
  return (
    <Modal
      open={open}
      title={
        `${t("aiEngine.crossCategorical")}`
      }
      closable={true}
      onCancel={handleCancel}
      footer={
        <Space style={{
          justifyContent: 'flex-end'
        }} >
          <StyledButton key="clear" onClick={handleClear}>
            {t("common.clear")}
          </StyledButton>
          <StyledButton
            key="ok"
            type="primary"
            onClick={handleCancel}
          >
            {t("common.ok")}
          </StyledButton>
          <StyledButton
            key="cancel"
            onClick={handleCancel}
          >
            {t("common.cancel")}
          </StyledButton>
        </Space>
      }
    >
      <Space direction="vertical" style={{ width: "100%", gap: 20 }}>
        <Space direction="vertical" style={{ width: "100%" }}>
          <Text strong>{t("common.selectCategory")}</Text>
          <Select
            mode="multiple"
            style={{ width: "100%" }}
            value={crossCategoricalSelectedCategories[currentSelectedStage]}
            onChange={(e: any) => {
              setCrossCategoricalSelectedCategories((state: any) => {
                const newState = JSON.parse(JSON.stringify(state))

                newState[currentSelectedStage] = e
                return { ...newState }
              }
              )
            }}
            allowClear
            dropdownRender={(menu) => {
              return (
                <div>
                  <Checkbox
                    style={{ padding: 10 }}
                    checked={crossCategoricalSelectedCategories[currentSelectedStage]?.length === options?.length}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setCrossCategoricalSelectedCategories((state: any) => {
                          const newState = JSON.parse(JSON.stringify(state))
                          newState[currentSelectedStage] = options?.map((item: any) => item?.props?.value)
                          return { ...newState }
                        })
                      } else {
                        setCrossCategoricalSelectedCategories((state: any) => {
                          const newState = JSON.parse(JSON.stringify(state))
                          newState[currentSelectedStage] = []
                          return { ...newState }
                        })
                      }
                    }}
                  >
                    {`${t("common.selectAll")} ${t("common.category")}`}
                  </Checkbox>
                  {menu}
                </div>
              )
            }}
          >
            {options}
          </Select>
        </Space>

        <Space style={{
          display: 'flex',
          alignItems: 'center'
        }}>
          <Space direction="vertical">
            <Text strong>{t("common.minQuantity")}</Text>
            <Input
              value={crossCategoricalValues[currentSelectedStage]?.min ?? null}
              onBlur={(e: any) => {
                setCrossCategoricalValues((state: any) => {
                  const convertedValue = convertValue(e.target.value)
                  const newState = JSON.parse(JSON.stringify(state))

                  if (isNaN(convertedValue)) {
                    message.error(t("aiEngine.pleaseEnterAValidNumber"),)
                  } else if (newState[currentSelectedStage].max !== null) {
                    if (convertedValue > convertValue(newState[currentSelectedStage].max)) {
                      newState[currentSelectedStage].min = null
                      message.error(t("aiEngine.minShouldBeLessThanMax"))
                    }
                  }
                  else {
                    const value = getCrossCategoryContraintValue(e.target.value)
                    newState[currentSelectedStage].min = value
                  }

                  return { ...newState }
                })
              }}
              onChange={(e: any) => {
                setCrossCategoricalValues((state: any) => {
                  const newState = JSON.parse(JSON.stringify(state))
                  newState[currentSelectedStage].min = getCrossCategoryContraintValue(e.target.value)

                  return { ...newState }
                })
              }}
            />
          </Space>

          <div>~</div>

          <Space direction="vertical">
            <Text strong>{t("common.maxQuantity")}</Text>
            <Input
              value={crossCategoricalValues[currentSelectedStage]?.max ?? null}
              onBlur={(e: any) => {
                setCrossCategoricalValues((state: any) => {
                  const convertedValue = convertValue(e.target.value)
                  const newState = JSON.parse(JSON.stringify(state))

                  if (isNaN(convertedValue)) {
                    message.error(t("aiEngine.pleaseEnterAValidNumber"),)
                  } else if (newState[currentSelectedStage].min !== null) {
                    if (convertedValue < convertValue(newState[currentSelectedStage].min)) {
                      newState[currentSelectedStage].max = null
                      message.error(t("aiEngine.maxShouldBeGreaterThanMin"))
                    }
                  }
                  else {
                    newState[currentSelectedStage].max = getCrossCategoryContraintValue(e.target.value)
                  }
                  return { ...newState }
                })
              }}
              onChange={(e: any) => {
                setCrossCategoricalValues((state: any) => {
                  const newState = JSON.parse(JSON.stringify(state))
                  newState[currentSelectedStage].max = getCrossCategoryContraintValue(e.target.value)

                  return { ...newState }
                })
              }}
            />
          </Space>
        </Space>

      </Space>
    </Modal>

  )

}

const CONSTRAINTS_STEP_NUMBER = 1
const OBJECTIVES_STEP_NUMBER = 0

const BottomButtons = ({
  generateExp,
  clearState,
  newInverseModel,
  setUnsavedChanges,

  currentSelectedStage,
  activePredictionInputTab,
  setActivePredictionInputTab,
  setCurrentSelectedStage,
  selectedStages,
  validationsForSteps,
  setConfigsOpenedViaPredictButton,
  isMultiStageModel,
  modelConfigData
}: {
  generateExp: any
  clearState: any
  newInverseModel: any
  setUnsavedChanges: any
  currentSelectedStage: number
  activePredictionInputTab: any
  setActivePredictionInputTab: any
  setCurrentSelectedStage: any
  selectedStages: any
  validationsForSteps: any,
  setConfigsOpenedViaPredictButton: any,
  isMultiStageModel: boolean,
  modelConfigData: any,
}) => {
  const [t] = useTranslate()
  const dispatch = useDispatch()
  let query = useQuery();
  let modelVersion = query?.get('version');
  const isSidebarCollapsed = useSelector((state: StoreState) => state.sidebar.collapsed)

  const isLastStage = useMemo(() => {
    return currentSelectedStage === selectedStages[1]
  }, [currentSelectedStage, selectedStages])

  const isFirstStage = useMemo(() => {
    return currentSelectedStage === selectedStages[0]
  }, [currentSelectedStage, selectedStages])

  const isFirstStep = useMemo(() => {
    if (isLastStage) {
      return activePredictionInputTab[currentSelectedStage] === OBJECTIVES_STEP_NUMBER
    } else {
      return activePredictionInputTab[currentSelectedStage] === CONSTRAINTS_STEP_NUMBER
    }
  }, [activePredictionInputTab, currentSelectedStage, isLastStage])

  const isLastStep = useMemo(() => {
    if (isLastStage) {
      return activePredictionInputTab[currentSelectedStage] === CONSTRAINTS_STEP_NUMBER
    } else {
      return activePredictionInputTab[currentSelectedStage] === CONSTRAINTS_STEP_NUMBER
    }
  }, [activePredictionInputTab, currentSelectedStage, isLastStage])

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        padding: '10px 96px 10px 32px',
        marginLeft: isSidebarCollapsed ? COLLAPSED_SIDEBAR_WIDTH : OPEN_SIDEBAR_WIDTH,
        background: '#FAFAFA',
        position: 'fixed',
        bottom: 0,
        right: 0,
        width: '-webkit-fill-available',
        zIndex: 3
      }}
    >
      {/* <Popconfirm
        okText={t("common.ok")}
        cancelText={t("common.cancel")}
        title={t("common.resetTable")}
        onConfirm={clearState}
      >
        <StyledButton type="primary" ghost>
          {t("compare.clear")}
        </StyledButton>
      </Popconfirm> */}

      {/* Previous Button */}
      {
        isFirstStep ? (
          isFirstStage ? <div /> : <StyledButton
            type="default"
            onClick={() => {
              if (validationsForSteps()) {
                setCurrentSelectedStage((prev: any) => {
                  const stage = prev - 1
                  if (!Object.keys(modelConfigData[stage - 1] || {}).length) {
                    dispatch(
                      modelsConfigRequest({
                        version: modelVersion,
                        isMultiStage: isMultiStageModel,
                        stage_name: modelConfigData?.[0]?.all_stages?.[stage - 1],
                      }),
                    )
                  }
                  return prev - 1
                })
              }
            }}
            style={{ outline: 'none' }}
          >
            {t('common.previousStage')}
          </StyledButton>
        ) : <StyledButton
          type="default"
          onClick={() => {
            if (validationsForSteps()) {
              setActivePredictionInputTab((prev: any) => ({
                ...prev,
                [currentSelectedStage]: activePredictionInputTab[currentSelectedStage] - 1
              }))
            }
          }}
          style={{ outline: 'none' }}
        >
          {t('common.back')}
        </StyledButton>
      }

      {/* Next Button */}
      {isLastStep ? (
        isLastStage ? (
          <Tooltip title={newInverseModel.inverseStatus === AsyncStates.LOADING ? t('aiEngine.inverseModel.predictingExperiments') : ''}>
            <StyledButton
              loading={newInverseModel.inverseStatus === AsyncStates.LOADING}
              type="primary"
              onClick={() => {
                if (validationsForSteps()) {
                  setUnsavedChanges(false)
                  setConfigsOpenedViaPredictButton(true)
                }
              }}
              style={{ outline: 'none' }}
            >
              {newInverseModel.inverseStatus === AsyncStates.LOADING ? t('aiEngine.predicting') : t('aiEngine.inverseModel.predictExperiments')}
            </StyledButton>
          </Tooltip>
        ) : (
          <StyledButton
            type="primary"
            onClick={() => {
              if (validationsForSteps()) {
                setCurrentSelectedStage((prev: any) => {
                  const stage = prev + 1
                  if (!Object.keys(modelConfigData[stage - 1] || {}).length) {
                    dispatch(
                      modelsConfigRequest({
                        version: modelVersion,
                        isMultiStage: isMultiStageModel,
                        stage_name: modelConfigData?.[0]?.all_stages?.[stage - 1],
                      }),
                    )
                  }
                  return prev + 1
                })
              }
            }}
            style={{ outline: 'none' }}
          >
            {t('common.nextStage')}
          </StyledButton>
        )
      ) : (
        <StyledButton
          type="primary"
          onClick={() => {
            if (validationsForSteps()) {
              setActivePredictionInputTab((prev: any) => ({
                ...prev,
                [currentSelectedStage]: activePredictionInputTab[currentSelectedStage] + 1
              }))
            }
          }}
          style={{ outline: 'none' }}
        >
          {t('common.saveAndContinue')}
        </StyledButton>
      )}
    </div>
  )
}