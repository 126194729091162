import { put, takeLatest, call, select } from "redux-saga/effects";
import {
  classificationAnalysisApi,
  deleteMasksApi,
  deleteSemAnalysisFilesApi,
  deleteSemAnalysisMaskHeightWidthApi,
  deleteBatchApi,
  downloadSemAnalysisReportApi,
  exportBatchReportApi,
  fetchImageSubtractionApi,
  fetchInstanceDetectionApi,
  fetchSemAnalysisDataApi,
  fetchSemAnalysisListApi,
  fetchSemAnalysisMaskDataApi,
  fetchSemAnalysisMaskHeightWidthApi,
  fetchSemAnalysisScaleApi,
  fetchBatchInfoApi,
  processPaintFilmAnalysisApi,
  resetMasksApi,
  setImageSubtractionApi,
  setSemAnalysisMaskHeightWidthApi,
  updateClassificationApi,
  analysisTypesApi,
  recalibrateScaleApi,
  modifyPaintFilm,
  processFiberAnalysisApi,
  processWhiteObjectsApi,
  modifyWhiteObjectsApi,
  modifyPaintFilmBatchApi,
  modifyFiberAnalysisApi,
  downloadSegmentedImagesApi,
} from "src/services/semAnalysis";
import {
  fetchSemAnalysisDataRequest,
  fetchSemAnalysisDataSuccess,
  fetchSemAnalysisDataFailure,
  fetchSemAnalysisMaskDataRequest,
  fetchSemAnalysisMaskDataSuccess,
  fetchSemAnalysisMaskDataFailure,
  fetchSemAnalysisListRequest,
  fetchSemAnalysisListSuccess,
  fetchSemAnalysisListFailure,
  deleteSemAnalysisFilesSuccess,
  deleteSemAnalysisFilesFailure,
  deleteSemAnalysisFilesRequest,
  fetchSemAnalysisScaleRequest,
  fetchSemAnalysisScaleSuccess,
  fetchSemAnalysisScaleFailure,
  fetchImageSubtractionSuccess,
  fetchImageSubtractionFailure,
  fetchImageSubtractionRequest,
  setImageSubtractionSuccess,
  setImageSubtractionFailure,
  setImageSubtractionRequest,
  fetchInstanceDetectionSuccess,
  fetchInstanceDetectionFailure,
  fetchInstanceDetectionRequest,
  setSemAnalysisMaskHeightWidthRequest,
  setSemAnalysisMaskHeightWidthSuccess,
  setSemAnalysisMaskHeightWidthFailure,
  fetchSemAnalysisMaskHeightWidthRequest,
  fetchSemAnalysisMaskHeightWidthSuccess,
  fetchSemAnalysisMaskHeightWidthFailure,
  downloadSemAnalysisReportFailure,
  downloadSemAnalysisReportSuccess,
  downloadSemAnalysisReportRequest,
  fetchClassificationAnalysisRequest,
  deleteSemAnalysisMaskHeightWidthSuccess,
  deleteSemAnalysisMaskHeightWidthFailure,
  deleteSemAnalysisMaskHeightWidthRequest,
  fetchSemAnalysisMaskHeightWidthClear,
  fetchClassificationAnalysisSuccess,
  fetchClassificationAnalysisFailure,
  deleteMasksRequest,
  deleteMasksSuccess,
  deleteMasksFailure,
  updateClassificationRequest,
  updateClassificationFailure,
  updateClassificationSuccess,
  resetMasksRequest,
  resetMasksSuccess,
  resetMasksFailure,
  processPaintFilmAnalysisRequest,
  processPaintFilmAnalysisSuccess,
  processPaintFilmAnalysisFailure,
  fetchPaintFilmBatchInfoRequest,
  fetchPaintFilmBatchInfoFailure,
  fetchPaintFilmBatchInfoSuccess,
  deletePaintFilmBatchFailure,
  deletePaintFilmBatchSuccess,
  deletePaintFilmBatchRequest,
  paintFilmRecalibrateScaleRequest,
  paintFilmRecalibrateScaleSuccess,
  paintFilmRecalibrateScaleFailure,
  analysisTypesSuccess,
  analysisTypesFailure,
  analysisTypesRequest,
  fetchResidualBatchInfoRequest,
  deleteResidualBatchRequest,
  fetchResidualBatchInfoSuccess,
  fetchResidualBatchInfoFailure,
  deleteResidualBatchSuccess,
  deleteResidualBatchFailure,
  residualRecalibrateScaleRequest,
  residualRecalibrateScaleFailure,
  residualRecalibrateScaleSuccess,
  downloadBatchReportSuccess,
  downloadBatchReportFailure,
  downloadBatchReportRequest,
  modifyPaintFilmRequest,
  modifyPaintFilmSuccess,
  modifyPaintFilmFailure,
  processFiberAnalysisRequest,
  fetchFiberBatchInfoSuccess,
  fetchFiberBatchInfoFailure,
  deleteFiberBatchSuccess,
  deleteFiberBatchFailure,
  processFiberAnalysisSuccess,
  processFiberAnalysisFailure,
  fetchFiberBatchInfoRequest,
  deleteFiberBatchRequest,
  fiberRecalibrateScaleRequest,
  fiberRecalibrateScaleSuccess,
  fiberRecalibrateScaleFailure,
  processWhiteObjectsRequest,
  processWhiteObjectsSuccess,
  processWhiteObjectsFailure,
  modifyWhiteObjectsSuccess,
  modifyWhiteObjectsFailure,
  modifyWhiteObjectsRequest,
  modifyPaintFilmBatchRequest,
  modifyPaintFilmBatchSuccess,
  modifyPaintFilmBatchFailure,
  modifyFiberRequest,
  modifyFiberSuccess,
  modifyFiberFailure,
  downloadSegmentedImagesRequest,
  downloadSegmentedImagesFailure,
  downloadSegmentedImagesSuccess,
} from "../actions/semAnalysis";
import jwtManager from "src/utils/jwtManager";
import { message, notification } from "antd";
import { messages } from "src/utils/hooks";
import { LanguageUnion } from "src/utils/useTranslate";
import { StoreState } from "../configureStore";
import { IMAGE_ANALYSIS_TYPES } from "src/components/Analytics/SEMAnalysis/SEMAnalysisWrapper";

function* fetchSemAnalysisDataSaga({ payload }: any): Generator<any, any, any> {
  const ln: LanguageUnion = yield select(
    (state: StoreState) => state.language.current
  );
  try {
    const { defaultHeaders } = yield select((state) => state);
    const headers = { ...defaultHeaders, token: jwtManager.getToken() };
    const { data } = yield call(fetchSemAnalysisDataApi, payload, headers);
    if (data && data?.status !== "Failed") {
      yield put(fetchSemAnalysisDataSuccess(data));
      if (data.firebase_doc_id) message.success("SEM analysis started");
    } else {
      throw new Error(data.message)
    }
  } catch (error: any) {
    const errorMessage = error?.message || error?.response?.data?.message;
    yield put(fetchSemAnalysisDataFailure(errorMessage));
    notification.error({
      message: errorMessage || messages[ln].internal_server_error,
      duration: 5,
    });
  }
}

function* fetchClassificationAnalysisSaga({ payload }: any): Generator<any, any, any> {
  const ln: LanguageUnion = yield select(
    (state: StoreState) => state.language.current
  );
  try {
    const { defaultHeaders } = yield select((state) => state);
    const headers = { ...defaultHeaders, token: jwtManager.getToken() };
    const { data } = yield call(classificationAnalysisApi(payload.file_id), payload, headers);
    if (data) {
      yield put(fetchClassificationAnalysisSuccess(data));
    } else {
      yield put(fetchClassificationAnalysisFailure());
    }
  } catch (error: any) {
    yield put(fetchClassificationAnalysisFailure());
    const errorMessage = error?.response?.data?.message;
    notification.error({
      message: errorMessage || messages[ln].internal_server_error,
      duration: 5,
    });
  }
}

function* fetchSemAnalysisMaskDataSaga({
  payload,
}: any): Generator<any, any, any> {
  const ln: LanguageUnion = yield select(
    (state: StoreState) => state.language.current
  );
  try {
    const { defaultHeaders } = yield select((state) => state);
    const headers = { ...defaultHeaders, token: jwtManager.getToken() };
    const { data, status } = yield call(fetchSemAnalysisMaskDataApi, payload, headers);
    if (data && data?.Status === "Failed") {
      throw new Error(data.Comment)
    } else {
      if(status === 205) {
        notification.info({
          message: data?.message,
          duration: 5,
        });
      }
      yield put(fetchSemAnalysisMaskDataSuccess(data));
    }
  } catch (error: any) {
    yield put(fetchSemAnalysisMaskDataFailure());
    const errorMessage = error?.message || error?.response?.data?.message;
    notification.error({
      message: errorMessage || messages[ln].internal_server_error,
      duration: 5,
    });
  }
}

function* fetchSemAnalysisListSaga({ payload }: any): Generator<any, any, any> {
  const ln: LanguageUnion = yield select(
    (state: StoreState) => state.language.current
  );
  try {
    const { defaultHeaders } = yield select((state) => state);
    const headers = { ...defaultHeaders, token: jwtManager.getToken() };
    const { data } = yield call(fetchSemAnalysisListApi(payload.analysisType), payload, headers);
    if (data) {
      yield put(fetchSemAnalysisListSuccess(data));
    } else {
      yield put(fetchSemAnalysisListFailure());
    }
  } catch (error: any) {
    yield put(fetchSemAnalysisListFailure());
    const errorMessage = error?.response?.data?.message;
    notification.error({
      message: errorMessage || messages[ln].internal_server_error,
      duration: 5,
    });
  }
}

function* deleteSemAnalysisFilesSaga({ payload }: any): Generator<any, any, any> {
  const ln: LanguageUnion = yield select(
    (state: StoreState) => state.language.current
  );
  try {
    const { defaultHeaders } = yield select((state) => state);
    const headers = { ...defaultHeaders, token: jwtManager.getToken() };
    const { status } = yield call(deleteSemAnalysisFilesApi(payload.analysis_type), payload, headers);
    if (status === 200) {
      yield put(deleteSemAnalysisFilesSuccess(status));
    } else {
      yield put(deleteSemAnalysisFilesFailure());
    }
  } catch (error: any) {
    yield put(deleteSemAnalysisFilesFailure());
    const errorMessage = error?.response?.data?.message;
    notification.error({
      message: errorMessage || messages[ln].internal_server_error,
      duration: 5,
    });
  }
}

function* fetchSemAnalysisScaleSaga({
  payload,
}: any): Generator<any, any, any> {
  const ln: LanguageUnion = yield select(
    (state: StoreState) => state.language.current
  );
  try {
    const { defaultHeaders } = yield select((state) => state);
    const headers = { ...defaultHeaders, token: jwtManager.getToken() };
    const { data } = yield call(fetchSemAnalysisScaleApi, payload, headers);
    if (data && data?.status === "Success") {
      yield put(fetchSemAnalysisScaleSuccess(data));
    } else {
      throw new Error(data.message)
    }
  } catch (error: any) {
    const errorMessage = error?.message || error?.response?.data?.message;
    yield put(fetchSemAnalysisScaleFailure(errorMessage));
    notification.error({
      message: errorMessage || messages[ln].internal_server_error,
      duration: 5,
    });
  }
}

function* fetchImageSubtractionSaga({
  payload,
}: any): Generator<any, any, any> {
  const ln: LanguageUnion = yield select(
    (state: StoreState) => state.language.current
  );
  try {
    const { defaultHeaders } = yield select((state) => state);
    const headers = { ...defaultHeaders, token: jwtManager.getToken() };
    const { data } = yield call(fetchImageSubtractionApi, payload, headers);
    if (data) {
      yield put(fetchImageSubtractionSuccess(data));
    } else {
      yield put(fetchImageSubtractionFailure());
    }
  } catch (error: any) {
    yield put(fetchImageSubtractionFailure());
    const errorMessage = error?.response?.data?.message;
    notification.error({
      message: errorMessage || messages[ln].internal_server_error,
      duration: 5,
    });
  }
}

function* setImageSubtractionSaga({ payload }: any): Generator<any, any, any> {
  const ln: LanguageUnion = yield select(
    (state: StoreState) => state.language.current
  );
  try {
    const { defaultHeaders } = yield select((state) => state);
    const headers = { ...defaultHeaders, token: jwtManager.getToken() };
    const { data } = yield call(setImageSubtractionApi, payload, headers);
    if (data && data?.status === "Failed") {
      throw new Error(data.message)
    } else {
      yield put(setImageSubtractionSuccess(data));
    }
  } catch (error: any) {
    const errorMessage = error?.message || error?.response?.data?.message;
    yield put(setImageSubtractionFailure(errorMessage));
    notification.error({
      message: errorMessage || messages[ln].internal_server_error,
      duration: 5,
    });
  }
}

function* fetchInstanceDetectionSaga({
  payload,
}: any): Generator<any, any, any> {
  const ln: LanguageUnion = yield select(
    (state: StoreState) => state.language.current
  );
  try {
    const { defaultHeaders } = yield select((state) => state);
    const headers = { ...defaultHeaders, token: jwtManager.getToken() };
    const { data } = yield call(fetchInstanceDetectionApi, payload, headers);
    if (data) {
      yield put(fetchInstanceDetectionSuccess(data));
    } else {
      yield put(fetchInstanceDetectionFailure());
    }
  } catch (error: any) {
    yield put(fetchInstanceDetectionFailure());
    const errorMessage = error?.response?.data?.message;
    notification.error({
      message: errorMessage || messages[ln].internal_server_error,
      duration: 5,
    });
  }
}

function* setSemAnalysisMaskHeightWidthSaga({
  payload,
}: any): Generator<any, any, any> {
  const ln: LanguageUnion = yield select(
    (state: StoreState) => state.language.current
  );
  try {
    const { defaultHeaders } = yield select((state) => state);
    const headers = { ...defaultHeaders, token: jwtManager.getToken() };
    const { data } = yield call(
      setSemAnalysisMaskHeightWidthApi(payload.file_id, payload.analysis_type),
      payload,
      headers
    );
    if (data) {
      yield put(setSemAnalysisMaskHeightWidthSuccess(data));
      yield put(fetchSemAnalysisMaskHeightWidthRequest(payload));
    } else {
      yield put(setSemAnalysisMaskHeightWidthFailure());
    }
  } catch (error: any) {
    yield put(setSemAnalysisMaskHeightWidthFailure());
    const errorMessage = error?.response?.data?.message;
    notification.error({
      message: errorMessage || messages[ln].internal_server_error,
      duration: 5,
    });
  }
}

function* deleteSemAnalysisMaskHeightWidthSaga({
  payload,
}: any): Generator<any, any, any> {
  const ln: LanguageUnion = yield select(
    (state: StoreState) => state.language.current
  );
  try {
    const { defaultHeaders } = yield select((state) => state);
    const headers = { ...defaultHeaders, token: jwtManager.getToken() };
    const { data } = yield call(
      deleteSemAnalysisMaskHeightWidthApi(payload.file_id, payload.analysis_type),
      payload,
      headers
    );
    if (data) {
      yield put(deleteSemAnalysisMaskHeightWidthSuccess(data));
      yield put(fetchSemAnalysisMaskHeightWidthClear())
    } else {
      yield put(deleteSemAnalysisMaskHeightWidthFailure());
    }
  } catch (error: any) {
    yield put(deleteSemAnalysisMaskHeightWidthFailure());
    const errorMessage = error?.response?.data?.message;
    notification.error({
      message: errorMessage || messages[ln].internal_server_error,
      duration: 5,
    });
  }
}

function* fetchSemAnalysisMaskHeightWidthSaga({
  payload,
}: any): Generator<any, any, any> {
  const ln: LanguageUnion = yield select(
    (state: StoreState) => state.language.current
  );
  try {
    const { defaultHeaders } = yield select((state) => state);
    const headers = { ...defaultHeaders, token: jwtManager.getToken() };
    const { data } = yield call(
      fetchSemAnalysisMaskHeightWidthApi(payload.file_id, payload.analysis_type),
      payload,
      headers
    );
    if (data) {
      yield put(fetchSemAnalysisMaskHeightWidthSuccess(data));
    } else {
      yield put(fetchSemAnalysisMaskHeightWidthFailure());
    }
  } catch (error: any) {
    yield put(fetchSemAnalysisMaskHeightWidthFailure());
    const errorMessage = error?.response?.data?.message;
    notification.error({
      message: errorMessage || messages[ln].internal_server_error,
      duration: 5,
    });
  }
}

function* downloadSemAnalysisReportSaga({
  payload,
}: any): Generator<any, any, any> {
  const ln: LanguageUnion = yield select(
    (state: StoreState) => state.language.current
  );
  try {
    const { defaultHeaders } = yield select((state) => state);
    const headers = { ...defaultHeaders, token: jwtManager.getToken() };
    const { data } = yield call(downloadSemAnalysisReportApi, payload, headers);
    if (data) {
      yield put(downloadSemAnalysisReportSuccess(data?.download_link));
      window.open(data?.download_link, "_blank");
    } else {
      yield put(downloadSemAnalysisReportFailure());
    }
  } catch (error: any) {
    yield put(downloadSemAnalysisReportFailure());
    const errorMessage = error?.response?.data?.message;
    notification.error({
      message: errorMessage || messages[ln].internal_server_error,
      duration: 5,
    });
  }
}

function* deleteMasksSaga({
  payload,
}: any): Generator<any, any, any> {
  const ln: LanguageUnion = yield select(
    (state: StoreState) => state.language.current
  );
  try {
    const { defaultHeaders } = yield select((state) => state);
    const headers = { ...defaultHeaders, token: jwtManager.getToken() };
    const { data } = yield call(
      deleteMasksApi,
      payload,
      headers
    );
    if (data) {
      yield put(fetchImageSubtractionSuccess(data));
      yield put(deleteMasksSuccess(data));
    } else {
      yield put(deleteMasksFailure());
    }
  } catch (error: any) {
    yield put(deleteMasksFailure());
    const errorMessage = error?.response?.data?.message;
    notification.error({
      message: errorMessage || messages[ln].internal_server_error,
      duration: 5,
    });
  }
}

function* updateClassificationSaga({
  payload,
}: any): Generator<any, any, any> {
  const ln: LanguageUnion = yield select(
    (state: StoreState) => state.language.current
  );
  try {
    const { defaultHeaders } = yield select((state) => state);
    const headers = { ...defaultHeaders, token: jwtManager.getToken() };
    const { data } = yield call(
      updateClassificationApi,
      payload,
      headers
    );
    if (data) {
      yield put(updateClassificationSuccess(data));
    } else {
      yield put(updateClassificationFailure());
    }
  } catch (error: any) {
    yield put(updateClassificationFailure());
    const errorMessage = error?.response?.data?.message;
    notification.error({
      message: errorMessage || messages[ln].internal_server_error,
      duration: 5,
    });
  }
}

function* resetMasksSaga({
  payload,
}: any): Generator<any, any, any> {
  const ln: LanguageUnion = yield select(
    (state: StoreState) => state.language.current
  );
  try {
    const { defaultHeaders } = yield select((state) => state);
    const headers = { ...defaultHeaders, token: jwtManager.getToken() };
    const { data } = yield call(
      resetMasksApi(payload.batch_id, payload.file_id),
      payload,
      headers
    );
    if (data) {
      yield put(fetchImageSubtractionSuccess(data));
      yield put(resetMasksSuccess(data));
    } else {
      yield put(resetMasksFailure());
    }
  } catch (error: any) {
    yield put(resetMasksFailure());
    const errorMessage = error?.response?.data?.message;
    notification.error({
      message: errorMessage || messages[ln].internal_server_error,
      duration: 5,
    });
  }
}

function* processPaintFilmAnalysisSaga({ payload }: any): Generator<any, any, any> {
  const ln: LanguageUnion = yield select(
    (state: StoreState) => state.language.current
  );
  try {
    const { defaultHeaders } = yield select((state) => state);
    const headers = { ...defaultHeaders, token: jwtManager.getToken() };
    const { data } = yield call(processPaintFilmAnalysisApi, payload, headers);
    if (data && data.status === "Failed") {
      throw new Error(data.message);
    } else {
      yield put(processPaintFilmAnalysisSuccess(data));
    }
  } catch (error: any) {
    const errorMessage = error?.message || error?.response?.data?.message;
    yield put(processPaintFilmAnalysisFailure(errorMessage));
    notification.error({
      message: errorMessage || messages[ln].internal_server_error,
      duration: 5,
    });
  }
}


function* fetchBatchInfoSaga({
  payload,
}: any): Generator<any, any, any> {
  const ln: LanguageUnion = yield select(
    (state: StoreState) => state.language.current
  );
  const { analysisTypes } = yield select(
    (state: StoreState) => state.semAnalysis
  );
  const analysisType = Object.keys(analysisTypes).find(key => analysisTypes[key] === payload.analysis_type);
  try {
    const { defaultHeaders } = yield select((state) => state);
    const headers = { ...defaultHeaders, token: jwtManager.getToken() };
    const { data } = yield call(
      fetchBatchInfoApi(payload.analysis_type, payload?.batch_id),
      payload,
      headers
    );
    if (data) {
      if (analysisType === IMAGE_ANALYSIS_TYPES.PAINT_FILM_ANALYSIS) yield put(fetchPaintFilmBatchInfoSuccess(data));
      if (analysisType === IMAGE_ANALYSIS_TYPES.RESIDUAL_ANALYSIS) yield put(fetchResidualBatchInfoSuccess(data));
      if (analysisType === IMAGE_ANALYSIS_TYPES.FIBER_ANALYSIS) yield put(fetchFiberBatchInfoSuccess(data));
    } else {
      if (analysisType === IMAGE_ANALYSIS_TYPES.PAINT_FILM_ANALYSIS) yield put(fetchPaintFilmBatchInfoFailure());
      if (analysisType === IMAGE_ANALYSIS_TYPES.RESIDUAL_ANALYSIS) yield put(fetchResidualBatchInfoFailure());
      if (analysisType === IMAGE_ANALYSIS_TYPES.FIBER_ANALYSIS) yield put(fetchFiberBatchInfoFailure());
    }
  } catch (error: any) {
    if (analysisType === IMAGE_ANALYSIS_TYPES.PAINT_FILM_ANALYSIS) yield put(fetchPaintFilmBatchInfoFailure());
    if (analysisType === IMAGE_ANALYSIS_TYPES.RESIDUAL_ANALYSIS) yield put(fetchResidualBatchInfoFailure());
    if (analysisType === IMAGE_ANALYSIS_TYPES.FIBER_ANALYSIS) yield put(fetchFiberBatchInfoFailure());
    const errorMessage = error?.response?.data?.message;
    notification.error({
      message: errorMessage || messages[ln].internal_server_error,
      duration: 5,
    });
  }
}

function* exportBatchReportSaga({
  payload,
}: any): Generator<any, any, any> {
  const ln: LanguageUnion = yield select(
    (state: StoreState) => state.language.current
  );
  try {
    const { defaultHeaders } = yield select((state) => state);
    const headers = { ...defaultHeaders, token: jwtManager.getToken() };
    const { data } = yield call(exportBatchReportApi, payload, headers);
    if (data?.status === "Failed") {
      throw new Error(data.message);
    } else {
      yield put(downloadBatchReportSuccess(data?.download_link));
      window.open(data?.download_link, "_blank");
    }
  } catch (error: any) {
    const errorMessage = error?.message || error?.response?.data?.message;
    yield put(downloadBatchReportFailure(errorMessage));
    notification.error({
      message: errorMessage || messages[ln].internal_server_error,
      duration: 5,
    });
  }
}

function* deleteBatchSaga({ payload }: any): Generator<any, any, any> {
  const ln: LanguageUnion = yield select(
    (state: StoreState) => state.language.current
  );
  const { analysisTypes } = yield select(
    (state: StoreState) => state.semAnalysis
  );
  const analysisType = Object.keys(analysisTypes).find(key => analysisTypes[key] === payload.analysis_type);
  try {
    const { defaultHeaders } = yield select((state) => state);
    const headers = { ...defaultHeaders, token: jwtManager.getToken() };
    const { status } = yield call(deleteBatchApi(payload.analysis_type), payload, headers);
    if (status === 200) {
      if (analysisType === IMAGE_ANALYSIS_TYPES.PAINT_FILM_ANALYSIS) yield put(deletePaintFilmBatchSuccess(status));
      if (analysisType === IMAGE_ANALYSIS_TYPES.RESIDUAL_ANALYSIS) yield put(deleteResidualBatchSuccess(status));
      if (analysisType === IMAGE_ANALYSIS_TYPES.FIBER_ANALYSIS) yield put(deleteFiberBatchSuccess(status));
    } else {
      if (analysisType === IMAGE_ANALYSIS_TYPES.PAINT_FILM_ANALYSIS) yield put(deletePaintFilmBatchFailure());
      if (analysisType === IMAGE_ANALYSIS_TYPES.RESIDUAL_ANALYSIS) yield put(deleteResidualBatchFailure());
      if (analysisType === IMAGE_ANALYSIS_TYPES.FIBER_ANALYSIS) yield put(deleteFiberBatchFailure());
    }
  } catch (error: any) {
    if (analysisType === IMAGE_ANALYSIS_TYPES.PAINT_FILM_ANALYSIS) yield put(deletePaintFilmBatchFailure());
    if (analysisType === IMAGE_ANALYSIS_TYPES.RESIDUAL_ANALYSIS) yield put(deleteResidualBatchFailure());
    if (analysisType === IMAGE_ANALYSIS_TYPES.FIBER_ANALYSIS) yield put(deleteFiberBatchFailure());
    const errorMessage = error?.response?.data?.message;
    notification.error({
      message: errorMessage || messages[ln].internal_server_error,
      duration: 5,
    });
  }
}

function* recalibrateScaleSaga({
  payload,
}: any): Generator<any, any, any> {
  const ln: LanguageUnion = yield select(
    (state: StoreState) => state.language.current
  );
  const { analysisTypes } = yield select(
    (state: StoreState) => state.semAnalysis
  );
  const analysisType = Object.keys(analysisTypes).find(key => analysisTypes[key] === payload.analysis_type);
  try {
    const { defaultHeaders } = yield select((state) => state);
    const headers = { ...defaultHeaders, token: jwtManager.getToken() };
    const { data } = yield call(recalibrateScaleApi, payload, headers);
    if (data) {
      if (analysisType === IMAGE_ANALYSIS_TYPES.PAINT_FILM_ANALYSIS) yield put(paintFilmRecalibrateScaleSuccess());
      if (analysisType === IMAGE_ANALYSIS_TYPES.RESIDUAL_ANALYSIS) yield put(residualRecalibrateScaleSuccess());
      if (analysisType === IMAGE_ANALYSIS_TYPES.FIBER_ANALYSIS) yield put(fiberRecalibrateScaleSuccess());
    } else {
      if (analysisType === IMAGE_ANALYSIS_TYPES.PAINT_FILM_ANALYSIS) yield put(paintFilmRecalibrateScaleFailure());
      if (analysisType === IMAGE_ANALYSIS_TYPES.RESIDUAL_ANALYSIS) yield put(residualRecalibrateScaleFailure());
      if (analysisType === IMAGE_ANALYSIS_TYPES.FIBER_ANALYSIS) yield put(fiberRecalibrateScaleFailure());
    }
  } catch (error: any) {
    const errorMessage = error?.response?.data?.message || messages[ln].internal_server_error;
    if (analysisType === IMAGE_ANALYSIS_TYPES.PAINT_FILM_ANALYSIS) yield put(paintFilmRecalibrateScaleFailure(errorMessage));
    if (analysisType === IMAGE_ANALYSIS_TYPES.RESIDUAL_ANALYSIS) yield put(residualRecalibrateScaleFailure());
    if (analysisType === IMAGE_ANALYSIS_TYPES.FIBER_ANALYSIS) yield put(fiberRecalibrateScaleFailure());
    notification.error({
      message: errorMessage,
      duration: 5,
    });
  }
}

function* analysisTypesSaga({
  payload,
}: any): Generator<any, any, any> {
  const ln: LanguageUnion = yield select(
    (state: StoreState) => state.language.current
  );
  try {
    const { defaultHeaders } = yield select((state) => state);
    const headers = { ...defaultHeaders, token: jwtManager.getToken() };
    const { data } = yield call(analysisTypesApi, payload, headers);
    if (data) {
      yield put(analysisTypesSuccess(data));
    } else {
      yield put(analysisTypesFailure());
    }
  } catch (error: any) {
    const errorMessage = error?.response?.data?.message || messages[ln].internal_server_error;
    yield put(analysisTypesFailure(errorMessage));
    notification.error({
      message: errorMessage,
      duration: 5,
    });
  }
}

function* modifyPaintFilmSaga({
  payload,
}: any): Generator<any, any, any> {
  const ln: LanguageUnion = yield select(
    (state: StoreState) => state.language.current
  );
  try {
    const { defaultHeaders } = yield select((state) => state);
    const headers = { ...defaultHeaders, token: jwtManager.getToken() };
    yield call(modifyPaintFilm, payload, headers);
    yield put(modifyPaintFilmSuccess());
  } catch (error: any) {
    const errorMessage = error?.response?.data?.message || messages[ln].internal_server_error;
    yield put(modifyPaintFilmFailure());
    notification.error({
      message: errorMessage,
      duration: 5,
    });
  }
}

function* modifyPaintFilmBatchSaga({
  payload,
}: any): Generator<any, any, any> {
  const ln: LanguageUnion = yield select(
    (state: StoreState) => state.language.current
  );
  try {
    const { defaultHeaders } = yield select((state) => state);
    const headers = { ...defaultHeaders, token: jwtManager.getToken() };
    const { data } = yield call(modifyPaintFilmBatchApi, payload, headers);
    if (data) {
      yield put(modifyPaintFilmBatchSuccess({ [payload.batch_id]: data.firebase_doc_id }));
      notification.success({
        message: "Started Applying Changes to Batch",
        duration: 5,
      });
    } else {
      yield put(modifyPaintFilmBatchFailure());
    }
  } catch (error: any) {
    const errorMessage = error?.response?.data?.message || messages[ln].internal_server_error;
    yield put(modifyPaintFilmBatchFailure());
    notification.error({
      message: errorMessage,
      duration: 5,
    });
  }
}

function* processFiberAnalysisSaga({ payload }: any): Generator<any, any, any> {
  const ln: LanguageUnion = yield select(
    (state: StoreState) => state.language.current
  );
  try {
    const { defaultHeaders } = yield select((state) => state);
    const headers = { ...defaultHeaders, token: jwtManager.getToken() };
    const { data } = yield call(processFiberAnalysisApi, payload, headers);
    if (data && data.status === "Failed") {
      throw new Error(data.message);
    } else {
      yield put(processFiberAnalysisSuccess(data));
    }
  } catch (error: any) {
    const errorMessage = error?.message || error?.response?.data?.message;
    yield put(processFiberAnalysisFailure(errorMessage));
    notification.error({
      message: errorMessage || messages[ln].internal_server_error,
      duration: 5,
    });
  }
}

function* processWhiteObjectSaga({ payload }: any): Generator<any, any, any> {
  const ln: LanguageUnion = yield select(
    (state: StoreState) => state.language.current
  );
  try {
    const { defaultHeaders } = yield select((state) => state);
    const headers = { ...defaultHeaders, token: jwtManager.getToken() };
    const { data } = yield call(processWhiteObjectsApi, payload, headers);
    if (data && data.status === "Failed") {
      throw new Error(data.message);
    } else {
      yield put(processWhiteObjectsSuccess(data));
    }
  } catch (error: any) {
    const errorMessage = error?.message || error?.response?.data?.message;
    yield put(processWhiteObjectsFailure(errorMessage));
    notification.error({
      message: errorMessage || messages[ln].internal_server_error,
      duration: 5,
    });
  }
}

function* modifyWhiteObjectSaga({ payload }: any): Generator<any, any, any> {
  const ln: LanguageUnion = yield select(
    (state: StoreState) => state.language.current
  );
  try {
    const { defaultHeaders } = yield select((state) => state);
    const headers = { ...defaultHeaders, token: jwtManager.getToken() };
    const { data } = yield call(modifyWhiteObjectsApi, payload, headers);
    if (data && data.status === "Failed") {
      throw new Error(data.message);
    } else {
      yield put(modifyWhiteObjectsSuccess(data));
    }
  } catch (error: any) {
    const errorMessage = error?.message || error?.response?.data?.message;
    yield put(modifyWhiteObjectsFailure(errorMessage));
    notification.error({
      message: errorMessage || messages[ln].internal_server_error,
      duration: 5,
    });
  }
}

function* modifyFiberSaga({
  payload,
}: any): Generator<any, any, any> {
  const ln: LanguageUnion = yield select(
    (state: StoreState) => state.language.current
  );
  try {
    const { defaultHeaders } = yield select((state) => state);
    const headers = { ...defaultHeaders, token: jwtManager.getToken() };
    yield call(modifyFiberAnalysisApi, payload, headers);
    yield put(modifyFiberSuccess());
  } catch (error: any) {
    const errorMessage = error?.response?.data?.message || messages[ln].internal_server_error;
    yield put(modifyFiberFailure());
    notification.error({
      message: errorMessage,
      duration: 5,
    });
  }
}


function* downloadSegmentedImagesSaga({
  payload,
}: any): Generator<any, any, any> {
  const ln: LanguageUnion = yield select(
    (state: StoreState) => state.language.current
  );
  try {
    const { defaultHeaders } = yield select((state) => state);
    const headers = { ...defaultHeaders, token: jwtManager.getToken() };
    const { data } = yield call(downloadSegmentedImagesApi, payload, headers, { responseType: "blob" });
    
    yield put(downloadSegmentedImagesSuccess());
    const fileUrl = URL.createObjectURL(data);
    let fileLink = document.createElement('a');
    fileLink.href = fileUrl;
    fileLink.download = String(payload?.batch_name || "batch_files").replace(/[^\w]/g, "-");
    fileLink.click();
    fileLink.remove();
  } catch (error: any) {
    const errorMessage = error?.message || error?.response?.data?.message;
    yield put(downloadSegmentedImagesFailure(errorMessage));
    notification.error({
      message: errorMessage || messages[ln].internal_server_error,
      duration: 5,
    });
  }
}

export default function* rootSaga(): Generator<any, any, any> {
  yield takeLatest(fetchSemAnalysisDataRequest, fetchSemAnalysisDataSaga);
  yield takeLatest(fetchClassificationAnalysisRequest, fetchClassificationAnalysisSaga);
  yield takeLatest(
    fetchSemAnalysisMaskDataRequest,
    fetchSemAnalysisMaskDataSaga
  );
  yield takeLatest(
    deleteSemAnalysisMaskHeightWidthRequest,
    deleteSemAnalysisMaskHeightWidthSaga
  );
  yield takeLatest(fetchSemAnalysisListRequest, fetchSemAnalysisListSaga);
  yield takeLatest(fetchSemAnalysisScaleRequest, fetchSemAnalysisScaleSaga);
  yield takeLatest(deleteSemAnalysisFilesRequest, deleteSemAnalysisFilesSaga);
  yield takeLatest(fetchImageSubtractionRequest, fetchImageSubtractionSaga);
  yield takeLatest(setImageSubtractionRequest, setImageSubtractionSaga);
  yield takeLatest(fetchInstanceDetectionRequest, fetchInstanceDetectionSaga);
  yield takeLatest(
    setSemAnalysisMaskHeightWidthRequest,
    setSemAnalysisMaskHeightWidthSaga
  );
  yield takeLatest(
    fetchSemAnalysisMaskHeightWidthRequest,
    fetchSemAnalysisMaskHeightWidthSaga
  );
  yield takeLatest(
    downloadSemAnalysisReportRequest,
    downloadSemAnalysisReportSaga
  );
  yield takeLatest(deleteMasksRequest, deleteMasksSaga);
  yield takeLatest(updateClassificationRequest, updateClassificationSaga);
  yield takeLatest(resetMasksRequest, resetMasksSaga);
  yield takeLatest(processPaintFilmAnalysisRequest, processPaintFilmAnalysisSaga);
  yield takeLatest(fetchPaintFilmBatchInfoRequest, fetchBatchInfoSaga);
  yield takeLatest(downloadBatchReportRequest, exportBatchReportSaga);
  yield takeLatest(deletePaintFilmBatchRequest, deleteBatchSaga);
  yield takeLatest(paintFilmRecalibrateScaleRequest, recalibrateScaleSaga);
  yield takeLatest(analysisTypesRequest, analysisTypesSaga);
  yield takeLatest(fetchResidualBatchInfoRequest, fetchBatchInfoSaga);
  yield takeLatest(deleteResidualBatchRequest, deleteBatchSaga);
  yield takeLatest(residualRecalibrateScaleRequest, recalibrateScaleSaga);
  yield takeLatest(modifyPaintFilmRequest, modifyPaintFilmSaga);
  yield takeLatest(processFiberAnalysisRequest, processFiberAnalysisSaga);
  yield takeLatest(fetchFiberBatchInfoRequest, fetchBatchInfoSaga);
  yield takeLatest(deleteFiberBatchRequest, deleteBatchSaga);
  yield takeLatest(fiberRecalibrateScaleRequest, recalibrateScaleSaga);
  yield takeLatest(processWhiteObjectsRequest, processWhiteObjectSaga);
  yield takeLatest(modifyWhiteObjectsRequest, modifyWhiteObjectSaga);
  yield takeLatest(modifyPaintFilmBatchRequest, modifyPaintFilmBatchSaga);
  yield takeLatest(modifyFiberRequest, modifyFiberSaga);
  yield takeLatest(downloadSegmentedImagesRequest, downloadSegmentedImagesSaga);
}
